import { Button, Dialog } from "@mui/material";
import DeleteIcon from "../../assets/CouponsAssets/GridActionIcons/DeleteIcon.svg";
import { toast } from "react-toastify";
import { ReactComponent as ActiveIcon } from "../../assets/ActiveIcon.svg";
import { ReactComponent as DeactiveIcon } from "../../assets/DeactiveIcon.svg";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store";
import { activeInActiveToggle } from "../../apis/LoyaltyMembersAPis";

type Props = {
  value: any;
  onClose: () => void;
  drawerType: string;
};

const DrawerComponentsLM = ({ value, onClose, drawerType }: Props) => {
  const accessToken = useSelector(
    (state: RootState) => state.userLogin.accessToken
  );
  const onSubmitHandler = async () => {
    if (drawerType === "inactive" || drawerType === "active") {
      const res = await activeInActiveToggle({
        accessToken: accessToken,
        loyalty_no: value?.loyalty_number,
      });

      if (res?.status === 200) {
        toast.success(res?.data?.message);
        onClose();
      } else {
        toast.error("Something went wrong!!");
        onClose();
      }
    }
  };
  return (
    <Dialog
      open={!!value}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="items-center justify-center px-6 py-5 space-y-7">
        <div className="flex space-x-2 ">
          {drawerType === "delete" && (
            <img src={DeleteIcon} alt="" className="w-14 h-14" />
          )}
          {drawerType === "inactive" && <DeactiveIcon className="w-14 h-14" />}
          {drawerType === "active" && <ActiveIcon className="w-14 h-14" />}
          <div>
            <h1 className="font-[500] text-black text-xl">Are you sure</h1>
            <h1 className="font-[500] text-black text-xl">
              Do you want to {drawerType} ?
            </h1>
          </div>
        </div>

        <div className="w-fll flex  justify-evenly">
          <Button
            onClick={onClose}
            variant="outlined"
            color="inherit"
            style={{
              borderRadius: 8,
              padding: "18px 36px",
              fontSize: "15px",
              // textDecoration:
              textTransform: "none",
              // fontFamily: "AvenirLTProHeavy",
              height: 20,
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={onSubmitHandler}
            variant="contained"
            color="primary"
            style={{
              borderRadius: 8,
              backgroundColor: "#000",
              padding: "18px 36px",
              fontSize: "15px",
              // textDecoration:
              textTransform: "none",
              // fontFamily: "AvenirLTProHeavy",
              height: 20,
            }}
          >
            {drawerType.charAt(0).toUpperCase() + drawerType.slice(1)}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DrawerComponentsLM;
