import { Button, Dialog } from "@mui/material";
import React from "react";
import { deleteCashWithId, editCashWithId } from "../../../apis/APIs";
import DeleteIcon from "../../../assets/CouponsAssets/GridActionIcons/DeleteIcon.svg";
import PublishIcon from "../../../assets/ResendIcon.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/Store";
import { toast } from "react-toastify";
import { ReactComponent as ActiveIcon } from "../../../assets/ActiveIcon.svg";
import { ReactComponent as DeactiveIcon } from "../../../assets/DeactiveIcon.svg";

interface Props {
  item: any;
  onClose: () => void;
  showDrawer: boolean;
  type: string;
}

const DrawerComponet = (props: Props) => {
  const accessToken = useSelector(
    (state: RootState) => state.userLogin?.accessToken
  );

  return (
    <Dialog
      open={props?.showDrawer}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
      onClose={props?.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {props?.type === "delete" && (
        <div className="items-center justify-center px-6 py-5 space-y-7">
          <div className="flex space-x-2 ">
            <img src={DeleteIcon} alt="" className="w-14 h-14" />
            <div>
              <h1 className="font-[500] text-black text-xl">Are you sure</h1>
              <h1 className="font-[500] text-black text-xl">
                Do you want to delete ?
              </h1>
            </div>
          </div>

          <div className="w-fll flex  justify-evenly">
            <Button
              onClick={props?.onClose}
              variant="outlined"
              color="inherit"
              style={{
                borderRadius: 8,
                padding: "18px 36px",
                fontSize: "15px",
                // textDecoration:
                textTransform: "none",
                // fontFamily: "AvenirLTProHeavy",
                height: 20,
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={async () => {
                const res = await deleteCashWithId(
                  `${props?.item?.id}`,
                  accessToken
                );
                if (res?.status === "Success") {
                  toast.success("Cash deleted.");
                  props?.onClose();
                } else {
                  toast.error("Cash delete failed.");
                  props?.onClose();
                }
              }}
              variant="contained"
              color="primary"
              style={{
                borderRadius: 8,
                backgroundColor: "#000",
                padding: "18px 36px",
                fontSize: "15px",
                // textDecoration:
                textTransform: "none",
                // fontFamily: "AvenirLTProHeavy",
                height: 20,
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      )}
      {props?.type === "resend" && (
        <div className="items-center justify-center px-6 py-5 space-y-7">
          <div className="flex space-x-2 ">
            <img src={PublishIcon} alt="" className="w-14 h-14" />
            <div>
              <h1 className="font-[500] text-black text-xl">Are you sure</h1>
              <h1 className="font-[500] text-black text-xl">
                Do you want to resend ?
              </h1>
            </div>
          </div>
          <div className="w-fll flex  justify-evenly  ">
            <Button
              onClick={props?.onClose}
              variant="outlined"
              color="inherit"
              style={{
                borderRadius: 8,
                padding: "18px 36px",
                fontSize: "15px",
                // textDecoration:
                textTransform: "none",
                // fontFamily: "AvenirLTProHeavy",
                height: 20,
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={async () => {}}
              variant="contained"
              color="primary"
              style={{
                borderRadius: 8,
                backgroundColor: "#000",
                padding: "18px 36px",
                fontSize: "15px",
                // textDecoration:
                textTransform: "none",
                // fontFamily: "AvenirLTProHeavy",
                height: 20,
              }}
            >
              Resend
            </Button>
          </div>
        </div>
      )}
      {props?.type === "deactiveIcon" && (
        <div className="items-center justify-center px-6 py-5 space-y-7">
          <div className="flex space-x-2 ">
            <DeactiveIcon className="w-14 h-14" />
            <div>
              <h1 className="font-[500] text-black text-xl">Are you sure</h1>
              <h1 className="font-[500] text-black text-xl">
                Do you want to inactive ?
              </h1>
            </div>
          </div>

          <div className="w-fll flex  justify-evenly">
            <Button
              onClick={props?.onClose}
              variant="outlined"
              color="inherit"
              style={{
                borderRadius: 8,
                padding: "18px 36px",
                fontSize: "15px",
                // textDecoration:
                textTransform: "none",
                // fontFamily: "AvenirLTProHeavy",
                height: 20,
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={async () => {
                const res: any = await editCashWithId(props.item, accessToken);
                if (res?.status === 200) {
                  toast.success("Cash inactive.");
                  props?.onClose();
                } else {
                  toast.error("Cash inactive failed.");
                  props?.onClose();
                }
              }}
              variant="contained"
              color="primary"
              style={{
                borderRadius: 8,
                backgroundColor: "#000",
                padding: "18px 36px",
                fontSize: "15px",
                // textDecoration:
                textTransform: "none",
                // fontFamily: "AvenirLTProHeavy",
                height: 20,
              }}
            >
              Deactive
            </Button>
          </div>
        </div>
      )}
      {props?.type === "activeIcon" && (
        <div className="items-center justify-center px-6 py-5 space-y-7">
          <div className="flex space-x-2 ">
            <ActiveIcon className="w-14 h-14" />
            <div>
              <h1 className="font-[500] text-black text-xl">Are you sure</h1>
              <h1 className="font-[500] text-black text-xl">
                Do you want to active ?
              </h1>
            </div>
          </div>

          <div className="w-fll flex  justify-evenly">
            <Button
              onClick={props?.onClose}
              variant="outlined"
              color="inherit"
              style={{
                borderRadius: 8,
                padding: "18px 36px",
                fontSize: "15px",
                // textDecoration:
                textTransform: "none",
                // fontFamily: "AvenirLTProHeavy",
                height: 20,
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={async () => {
                const res: any = await editCashWithId(props.item, accessToken);
                if (res?.status === 200) {
                  toast.success("Cash active.");
                  props?.onClose();
                } else {
                  toast.error("Cash actived failed.");
                  props?.onClose();
                }
              }}
              variant="contained"
              color="primary"
              style={{
                borderRadius: 8,
                backgroundColor: "#000",
                padding: "18px 36px",
                fontSize: "15px",
                // textDecoration:
                textTransform: "none",
                // fontFamily: "AvenirLTProHeavy",
                height: 20,
              }}
            >
              Active
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default DrawerComponet;
