import React, { useRef, useState, useEffect } from "react";

type Props = {};

const tabsData = [
  {
    label: "Active",
  },
  { label: "Inactive" },
  {
    label: "All",
  },
];

const FiltersByData = (props: Props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const tabsRef = useRef<(HTMLButtonElement | null)[]>([]);
  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener("resize", setTabPosition);
    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);

  return (
    <div>
      <div className="relative bg-[#dedede]  rounded-full px-4 py-[6px]">
        <div className="flex  space-x-6 ">
          {tabsData.map((tab, idx) => {
            return (
              <button
                key={idx}
                ref={(el) => (tabsRef.current[idx] = el)}
                className={` text-lg font-AvenirLTProHeavy w-16 ${
                  activeTabIndex === idx ? "text-white" : "text-black"
                } whitespace-pre  z-50
                `}
                onClick={() => setActiveTabIndex(idx)}
              >
                {tab.label}
              </button>
            );
          })}
        </div>
        <span
          className="absolute top-1 bottom-1 block  rounded-full bg-[#231f20] transition-all duration-300"
          style={{
            left: tabUnderlineLeft - 10,
            width: tabUnderlineWidth + 20,
          }}
        />
      </div>
    </div>
  );
};

export default FiltersByData;
