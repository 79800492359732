import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSpring, animated } from "@react-spring/web";
import HLCashEditView from "./HLMessageGridEditView";
import HLCashDuplicate from "./HLMessageDuplicate";
import HLCashCreateModal from "./HLMessageCreateModal";
import HLMessageGridEditView from "./HLMessageGridEditView";
import HLMessageDuplicate from "./HLMessageDuplicate";

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props,
  ref
) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
};
interface Props {
  item: any;
  onClose: () => void;
  showModal: boolean;
  modalType: string;
}
const ModalIndex = ({ item, onClose, showModal, modalType }: Props) => {
  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={showModal}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={showModal}>
          <Box sx={style}>
            {/* Edit or View*/}
            {modalType == "edit" && (
              <HLMessageGridEditView onClose={onClose} item={item} />
            )}
            {/*Duplicate*/}
            {modalType == "duplicate" && (
              <HLMessageDuplicate onClose={onClose} item={item} />
            )}
            {/* Create Modal */}
            {modalType == "create" && <HLCashCreateModal onClose={onClose} />}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
export default ModalIndex;
