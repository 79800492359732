import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const getKpisRead = (accessToken: string) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/admin/api/stat/kpi`,
    headers: {
      Authorization: `hlw ${accessToken}`,
    },
  };

  const res = axios
    .request(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

  return res;
};

// Get all communication statics
interface getCommunicationStaticsProps {
  accessToken: string;
  comm_type?: string;
}

export const getCommunicationStatics = ({
  accessToken,
  comm_type,
}: getCommunicationStaticsProps) => {
  // ?comm_type=message
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/admin/api/stat/communication`,
    headers: {
      Authorization: `hlw ${accessToken}`,
    },
  };
  if (comm_type) {
    config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/admin/api/stat/communication?comm_type=${comm_type}`,
      headers: {
        Authorization: `hlw ${accessToken}`,
      },
    };
  }
  const res = axios
    .request(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return res;
};

// Get kpi grid values
interface GridKPIInterface {
  accessToken: string;
  loyalty_type?: number;
}

export const getKpiGridValues = ({
  accessToken,
  loyalty_type,
}: GridKPIInterface) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/admin/api/loyalty_member/`,
    headers: {
      Authorization: `hlw ${accessToken}`,
      "Content-Type": "application/json",
    },
  };
  let config1 = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/admin/api/loyalty_member/`,
    headers: {
      Authorization: `hlw ${accessToken}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      filter: {
        loyalty_type_id: loyalty_type,
      },
    }),
  };

  const res = axios
    .request(loyalty_type ? config1 : config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return res;
};
