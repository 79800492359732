import moment from "moment";
import React, { useRef, useState } from "react";
import ArrowRight from "../assets/LoginAssets/ArrowRight.svg";
import { ReactComponent as CloseEye } from "../assets/LoginAssets/CloseEye.svg";
import { ReactComponent as OpenEye } from "../assets/LoginAssets/OpenEye.svg";
import { useDispatch } from "react-redux";
import { USER_LOGIN } from "../Redux/LogInSlice";
import { Button } from "@mui/material";
import SnackbarAlert from "../utils/SnackbarAlert";
import { ref } from "yup";
import { LoginApi } from "../apis/APIs";

type Props = {};

const Login = (props: Props) => {
  const [isPasswordVisiable, setPasswordVisiable] = useState("password");
  const dispatch = useDispatch();

  {
    /* Login component */
  }
  const userNameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [isCorrect, setIsCorrect] = useState<boolean>(true);
  const sanckbarRef = useRef<any>();
  return (
    <div className="flex flex-col justify-center h-full items-center -mt-20 sm:mt">
      <SnackbarAlert ref={sanckbarRef} />
      <div className="space-y-4 min-w-[80%] ">
        <h1 className="text-left text-3xl font-[avenirBlack] font-bold pb-2">
          Login
        </h1>
        {/* inputComponet username*/}
        <div className="space-y-2 flex flex-col">
          {/* label */}
          <h1 className="text-[#585858] text-[16px] font-[AvenirLTProHeavy] ">
            Username
          </h1>
          <input
            ref={userNameRef}
            type="text"
            className={`outline-none border-[1px] h-14 text-[14px] rounded-md p-2 px-2 placeholder:text-[14px] placeholder:font-avenirMediam ${
              !isCorrect && "border-red-500"
            }`}
            placeholder="Enter username"
          />
        </div>
        {/* inputComponet password*/}
        <div className="flex flex-col">
          <div className=" space-y-2 flex flex-col">
            {/* label */}
            <h1 className="text-[#585858] text-[16px] font-[AvenirLTProHeavy] ">
              Password
            </h1>
            <div
              className={`flex items-center border-[1px]   rounded-md  ${
                !isCorrect && "border-red-500"
              }`}
            >
              <input
                ref={passwordRef}
                type={isPasswordVisiable}
                className=" outline-none flex-grow  rounded-md   border-r-0  h-14 text-[14px] p-2 px-2 placeholder:text-[14px] placeholder:font-avenirMediam "
                placeholder="Enter password"
              />
              <button
                className="px-4"
                onClick={() => {
                  if (isPasswordVisiable === "password") {
                    setPasswordVisiable("text");
                  } else {
                    setPasswordVisiable("password");
                  }
                }}
              >
                {isPasswordVisiable === "password" ? (
                  <CloseEye className="w-4 h-4" />
                ) : (
                  <OpenEye className="w-4 h-4" />
                )}
              </button>
            </div>
          </div>
          {/* forgetpassword */}
          <h1 className="text-[12px] p-[.5px] pt-[.1rem] font-bold text-[#585858] font-[AvenirLTProHeavy] cursor-pointer select-none">
            Forgot Password?
          </h1>
        </div>

        <div className="flex items-end justify-end">
          {/* Submit btn */}
          <Button
            variant="contained"
            color="primary"
            style={{
              borderRadius: 8,
              backgroundColor: "#000",
              padding: "18px 20px",
              fontSize: "18px",
              // textDecoration:
              textTransform: "none",
              fontFamily: "AvenirLTProHeavy",
              height: 50,
            }}
            onClick={async () => {
              if (userNameRef.current?.value && passwordRef.current?.value) {
                const result = await LoginApi({
                  userName: userNameRef.current?.value.trim(),
                  password: passwordRef.current?.value.trim(),
                });
                if (result.status === 200) {
                  sanckbarRef.current._showTost(
                    result?.data?.message,
                    "success"
                  );
                  setTimeout(() => {
                    dispatch(
                      USER_LOGIN({
                        isLogin: true,
                        accessToken: result?.data?.token_obj?.access_token,
                        refreshToken: result?.data?.token_obj?.refressToken,
                      })
                    );
                  }, 2000);
                } else {
                  setIsCorrect(true);
                  sanckbarRef.current._showTost(
                    result?.response?.data?.error,
                    "error"
                  );
                }
              } else {
                sanckbarRef.current._showTost(
                  "Please provide username and password!",
                  "warning"
                );
                setIsCorrect(false);
              }
            }}
          >
            Login
            <img src={ArrowRight} className={"w-8 h-8 pl-4"} alt="ArrowRight" />
          </Button>
        </div>
      </div>
      <h1 className="absolute bottom-4 text-[#231F20] text-[13px] font-[AvenirLTProHeavy]">
        © {moment().format("YYYY")} Hardware & Lumber. All rights reserved.
      </h1>
    </div>
  );
};

export default Login;
