import LoginPage from "./LoginPage/LoginPage";
import { RootState } from "./Redux/Store";
import { useSelector } from "react-redux";
import UserPage from "./UserPage/UserPage";

function App() {
  const isLogIn = useSelector((state: RootState) => state.userLogin.isLogin);

  return <div>{isLogIn ? <UserPage /> : <LoginPage />}</div>;
}

export default App;
