import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CouponsCommunicationsHomePage from "./CouponsCommunicationsHomePage";
import CashCommunicationsHomePage from "./CashCommunicationsHomePage";
import MessagesCommunication from "./MessagesCommunication";
import EventsCommunications from "./EventsCommunications";

type Props = {};

const Tabs = [
  {
    label: "Cash Communications",
    content: "",
  },
  {
    label: "Coupons Communications",
    content: "",
  },
  {
    label: "Messages Communications",
    content: "",
  },
  {
    label: "Events Communications",
    content: "",
  },
];

const ChartsGrid = (props: Props) => {
  const location = useLocation();
  const params = location.state?.id;
  const nav = useNavigate();

  useEffect(() => {
    if (params === undefined) {
      nav("/");
    }
  }, [params]);

  //   Tab nagigation with animations
  const [activeTabIndex, setActiveTabIndex] = useState(Number(params));
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  const tabsRef = useRef<(HTMLButtonElement | null)[]>([]);

  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener("resize", setTabPosition);
    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);

  return (
    <div className="border-[1px] border-[#D7D4D4] p-4 rounded-lg">
      <div className="relative ">
        <div className="flex justify-between border-b-[1px] border-[#D7D4D4]">
          <div className="flex  space-x-6 ">
            {Tabs.map((tab, idx) => {
              return (
                <button
                  key={idx}
                  ref={(el) => (tabsRef.current[idx] = el)}
                  className={`pt-2 pb-3 text-lg font-AvenirLTProHeavy ${
                    activeTabIndex === idx ? "text-black" : "text-[#717171]"
                  } whitespace-pre `}
                  onClick={() => setActiveTabIndex(idx)}
                >
                  {tab.label}
                </button>
              );
            })}
          </div>
        </div>

        <span
          className="absolute bottom-0 block h-[5px] rounded-md bg-[#231f20] transition-all duration-300"
          style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
        />
      </div>
      <div className="py-4">
        {activeTabIndex === 0 && <CashCommunicationsHomePage />}
        {activeTabIndex === 1 && <CouponsCommunicationsHomePage />}
        {activeTabIndex === 2 && <MessagesCommunication />}
        {activeTabIndex === 3 && <EventsCommunications />}
      </div>
    </div>
  );
};

export default ChartsGrid;
