import React, { useEffect, useState } from "react";
import { ReactComponent as UploadIcon } from "../../assets/CouponsAssets/ModalIocns/UploadIcon.svg";
//@ts-ignore
import { useDropzone } from "react-dropzone";
import { Button } from "@mui/material";

type Props = {
  getFilePath: (value: any) => void;
  isFile?: any;
  isDisable?: boolean;
  url?: string;
};
const maxLength = 5242880;
function sizeValidation(file: any) {
  if (file.size > maxLength) {
    return {
      code: "File size is too large",
      message: `File size is larger than 5MB `,
    };
  }

  return null;
}

const base_url = process.env.REACT_APP_IMAGE_URL_LINK;
const UploadImage = ({ getFilePath, isFile, isDisable, url }: Props) => {
  const [files, setFiles] = useState([]);
  const [showPrev, setShowPrev] = useState(isFile ? true : false);
  const [urlImag, setShowUrlImage] = useState(url ? true : false);
  const { acceptedFiles, getRootProps, getInputProps, fileRejections } =
    useDropzone({
      disabled: isDisable,
      validator: sizeValidation,
      accept: {
        "image/*": [],
      },
      onDrop: (acceptedFiles) => {
        setFiles(
          //@ts-ignore
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });
  const Preview = acceptedFiles.map((file: any) => {
    return (
      <img
        src={file.preview}
        className="object-cover block  w-auto h-full rounded-md"
        alt={file.name}
      />
    );
  });

  useEffect(() => {
    if (acceptedFiles) {
      acceptedFiles?.map((file: any) => {
        getFilePath(file);
        setShowPrev(false);
        setShowUrlImage(false);
        return null;
      });
    }
  }, [acceptedFiles]);

  return (
    <div
      className={`border-[1px]  border-[#5F5E5E]  border-dashed h-[11.3rem] rounded-md outline-none ${
        isDisable && "bg-disable"
      }`}
    >
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{ cursor: "pointer" }}
        className=" flex flex-col w-full h-full flex-grow p-1 rounded-md focus:outline-none relative"
      >
        <input {...getInputProps()} />
        {urlImag ? (
          <>
            <div className="bg-white  rounded-md w-auto h-[128px]">
              <img
                 src={`${base_url}=${url}`}
                className="object-cover block  w-auto h-full rounded-md"
                alt={"file.name"}
              />
            </div>
            <div className="flex absolute bottom-1 items-end justify-end text-end space-x-1 right-1 ">
              <h1 className="text-[8px]  text-[#686767]">
                399px <span>X</span> 128px
                <br />
                maximum size 1Mb
                <br />
                (only jpg and png format)
              </h1>
              <h1 className="text-[13px]  bg-black text-white px-4 py-2 rounded-md select-none">
                Reupload
              </h1>
            </div>
          </>
        ) : (
          <>
            {!showPrev ? (
              <>
                {acceptedFiles?.length > 0 ? (
                  <>
                    <div className="bg-white  rounded-md w-auto h-[128px]">
                      {Preview}
                    </div>

                    <div className="flex absolute bottom-1 items-end justify-end text-end space-x-1 right-1 ">
                      <h1 className="text-[8px]  text-[#686767]">
                        399px <span>X</span> 128px
                        <br />
                        maximum size 1Mb
                        <br />
                        (only jpg and png format)
                      </h1>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          borderRadius: 8,
                          backgroundColor: "#000",
                          padding: "18px 20px",
                          fontSize: "13px",
                          // textDecoration:
                          textTransform: "none",
                          fontFamily: "AvenirLTProHeavy",
                          height: 20,
                        }}
                      >
                        Reupload
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-col items-center justify-center h-full space-y-1">
                      <UploadIcon />
                      <h1 className="text-[#686767] text-[13px] ">
                        Drop image to upload or click
                      </h1>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          borderRadius: 8,
                          backgroundColor: "#000",
                          padding: "18px 36px",
                          fontSize: "17px",
                          // textDecoration:
                          textTransform: "none",
                          fontFamily: "AvenirLTProHeavy",
                          height: 40,
                        }}
                      >
                        Upload
                      </Button>
                      <h1 className="text-[11px]  text-center text-[#686767]">
                        399px <span>X</span> 128px
                        <br />
                        maximum size 1Mb
                        <br />
                        (only jpg and png format)
                      </h1>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="bg-white  rounded-md w-auto h-[128px]">
                  <img
                    src={isFile.preview}
                    className="object-cover block  w-auto h-full rounded-md"
                    alt={"file.name"}
                  />
                </div>

                <div className="flex absolute bottom-1 items-end justify-end text-end space-x-1 right-1 ">
                  <h1 className="text-[8px]  text-[#686767]">
                    399px <span>X</span> 128px
                    <br />
                    maximum size 1Mb
                    <br />
                    (only jpg and png format)
                  </h1>
                  <h1 className="text-[13px]  bg-black text-white px-4 py-2 rounded-md select-none">
                    Reupload
                  </h1>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UploadImage;
