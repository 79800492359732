import * as React from "react";
import TotalIcon from "../../../assets/CouponsAssets/TotalIcon.svg";
import ActiveCoupons from "../../../assets/CouponsAssets/ActiveCoupons.svg";
import { KPIMenuType } from "../../../Typing";
import KpiComponents from "../../../Component/KpiComponents";
import ItemCompnents from "../../../Component/GridPage/ItemCompnents";
import CustomFilterComponents from "../../../Component/Filters/CustomFilterComponents";
import { readCouponData } from "../../../apis/APIs";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/Store";
import HLCashGrid from "./HLCashGrid";
import ModalIndex from "./ModalIndex";
import FiltersByData from "../../../Component/Filters/FiltersByData";
import { toast } from "react-toastify";

type Props = {};
const KpiItems: KPIMenuType[] = [
  {
    ItemIcon: TotalIcon,
    ItemQuantity: 70,
    Title: "Total",
    Color: "#FFDBDB",
  },
  {
    ItemIcon: ActiveCoupons,
    ItemQuantity: 52,
    Color: "#EFEFEF",
    Title: "Active",
  },
  {
    ItemIcon: ActiveCoupons,
    ItemQuantity: 18,
    Color: "#EFEFEF",
    Title: "Inactive",
  },
];

const HLCashIndex = (props: Props) => {
  const [data, setData] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [modalValue, setModalValue] = React.useState(null);
  const [modalType, setModalType] = React.useState("");
  const accessToken = useSelector(
    (state: RootState) => state.userLogin.accessToken
  );

  const _readApiCall = async (filetrs?: any) => {
    const res = await readCouponData("/admin/api/cash", accessToken, filetrs);
    if (res?.status === 200) {
      filetrs && toast.success(res?.data?.message);
      const data = res?.data?.data;
      if (data) {
        const dataWithIndex = data?.map((item: any, id: number) => {
          const ID = id + 1;
          return {
            ...item,
            sl: ID,
          };
        });

        setData(dataWithIndex);
      } else {
        setData([]);
      }
    }
  };

  const _onFilterBtnHandler = (filetrs: any) => {
    _readApiCall(filetrs);
  };

  React.useEffect(() => {
    _readApiCall();
  }, []);
  return (
    <div>
      {showModal && (
        <ModalIndex
          showModal={showModal}
          item={modalValue}
          onClose={() => {
            setShowModal(false);
            _readApiCall();
          }}
          modalType={modalType}
        />
      )}
      <div className="space-y-4 w-full px-4">
        <div className="flex items-center justify-between">
          <h1 className="text-xl font-AvenirLTProHeavy">
            H&L Cash Communications
          </h1>
          <h1 className="text-sm font-AvenirLTProHeavy text-[#8B8B8B] flex">
            Communication /
            <span className="text-black flex items-center ">
              <div className="w-2 h-2 bg-black rounded-full mx-2" /> Cash
            </span>
          </h1>
        </div>
        <KpiComponents
          KpiItems={KpiItems}
          clickText={"Create"}
          _ModalOpenHanlder={() => {
            setShowModal(true);
            setModalType("create");
          }}
        />
        <div className="w-full flex-grow   border-[1px] border-t-[##D7D4D4] rounded-2xl">
          {/* <Items /> */}
          <div className="flex justify-end py-2 px-2">
            <FiltersByData />
          </div>
          <div className="border-t-[1px] border-[#D7D4D4]" />
          {/* Filter Data */}
          <CustomFilterComponents onClick={_onFilterBtnHandler} />
          {/* Grid  */}
          <HLCashGrid
            data={data}
            getData={() => {
              _readApiCall();
            }}
          />
          {/* apiData*/}
        </div>
      </div>
    </div>
  );
};

export default HLCashIndex;
