import React, { useState } from "react";
import UploadXslx from "../../Component/UploadsComponents/UploadXslx";
import { ReactComponent as CloseModalIcon } from "../../assets/CouponsAssets/ModalIocns/CloseModalIcon.svg";
import { Button } from "@mui/material";
import { createLoyaltyMembersThroughExcl } from "../../apis/LoyaltyMembersAPis";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store";
import { ToastContainer, toast } from "react-toastify";
import { boolean } from "yup";
type Props = {
  _onCloseHandler: () => void;
};

const LoyaltyMemberUpload = (props: Props) => {
  const [values, setValues] = useState(null);
  const [isLoading, setIsLogin] = useState(false);
  const accessToken = useSelector(
    (state: RootState) => state.userLogin.accessToken
  );
  const onSubmitHandler = async () => {
    if (values) {
      setIsLogin(true);
      const res = await createLoyaltyMembersThroughExcl({
        accessToken: accessToken,
        excL: values,
      });

      if (res?.status === 200) {
        toast.success("Loyalty member added successfully.");
        props._onCloseHandler();
        setIsLogin(false);
      } else {
        toast.error(res?.message || "Something went wrong !!");
        props._onCloseHandler();
        setIsLogin(false);
      }
    } else {
      toast.error("Please select an excel file and try again!.");
      setIsLogin(false);
    }
  };
  return (
    <div className="min-w-[35rem] ">
      {/* Title Bar */}
      <div className="px-6 bg-[#FFDBDB] min-h-[4rem] w-full flex items-center justify-between rounded-t-xl ">
        <h1 className=" text-center text-[18px] font-AvenirLTProHeavy">
          Upload
        </h1>
        <CloseModalIcon
          onClick={props._onCloseHandler}
          className="cursor-pointer w-6 h-6"
        />
      </div>

      <div className="space-y-1">{/* Upload Image */}</div>

      <div className="space-y-1 px-8 py-4">
        <h1 className="text-black font-AvenirLTProHeavy text-[13px] ">
          Members
        </h1>
        {/* Upload Xslx */}
        <UploadXslx
          getFilePath={(e) => setValues(e)}
          isTemplete={require("../../assets/Templates/LoyaltyMemberCreate.xlsx")}
        />
      </div>
      <div className="border-[##C3C3C3] border-t border-[.5px]" />
      <div className="px-6 pb-3 space-x-3 py-3 flex justify-end">
        <button
          className="px-8 py-1 border-[#000000] border-[1px] rounded-md "
          onClick={props._onCloseHandler}
        >
          Cancel
        </button>
        <Button
          variant="contained"
          color="primary"
          style={{
            borderRadius: 8,
            backgroundColor: "#000",
            padding: "18px 36px",
            fontSize: "15px",
            // textDecoration:
            textTransform: "none",
            fontFamily: "AvenirLTProHeavy",
            height: 40,
          }}
          disabled={isLoading}
          onClick={onSubmitHandler}
        >
          {isLoading ? (
            <img
              src={require("../../assets/loading.png")}
              className="w-6 h-6 animate-spin  "
              alt="loading"
            />
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </div>
  );
};

export default LoyaltyMemberUpload;
