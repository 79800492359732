import React, { useEffect, useState } from "react";
import { ReactComponent as UploadIcon } from "../../assets/CouponsAssets/ModalIocns/UploadIcon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/CouponsAssets/ModalIocns/DownloadIcon.svg";
//@ts-ignore
import { useDropzone } from "react-dropzone";
import { Button } from "@mui/material";
import { ReactComponent as ExcelIcon } from "../../assets/ExcelIcon.svg";
type Props = {
  getFilePath?: (value: any) => void;
  isFile?: any;
  isDisable?: boolean;
  url?: string;
  isTemplete?: any;
};
const maxLength = 5242880;
function sizeValidation(file: any) {
  if (file.size > maxLength) {
    return {
      code: "File size is too large",
      message: `File size is larger than 5MB `,
    };
  }

  return null;
}
const UploadXslx = ({
  getFilePath,
  isFile,
  isDisable,
  url,
  isTemplete,
}: Props) => {
  const [showPrev, setShowPrev] = useState(url ? true : false);
  const { acceptedFiles, getRootProps, getInputProps, fileRejections } =
    useDropzone({
      disabled: isDisable,
      //   validator: sizeValidation,
      accept: {
        "application/vnd.ms-excel": [".xlsx"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xls",
        ],
      },
    });

  useEffect(() => {
    if (acceptedFiles) {
      acceptedFiles.map((file: any) => {
        setShowPrev(false);

        getFilePath && getFilePath(file);
      });
    }
  }, [acceptedFiles]);
  return (
    <div
      className={`border-[1px]  border-[#5F5E5E]  border-dashed h-[11.3rem] rounded-md outline-none ${
        isDisable && "bg-disable"
      }`}
    >
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{ cursor: "pointer" }}
        className=" flex flex-col w-full h-full flex-grow p-1 rounded-md focus:outline-none relative"
      >
        <input {...getInputProps()} />

        {!showPrev ? (
          <>
            {acceptedFiles?.length > 0 ? (
              <>
                <div className="bg-white  rounded-md w-auto h-[128px]">
                  {/* {Preview} */}
                  <div className="flex items-center justify-center h-full space-x-2">
                    <ExcelIcon className="w-6 h-6" />
                    <h1 className="text-[14px]">
                      {acceptedFiles.map((item) => item.name)}
                    </h1>
                  </div>
                </div>

                <div className="flex absolute bottom-1 items-center justify-end text-end space-x-4 right-1 ">
                  <a
                    href={
                      isTemplete
                        ? isTemplete
                        : require("../../assets/Templates/VoucherImport .xlsx")
                    }
                    download
                  >
                    <button
                      className="flex space-x-1 items-center justify-center bg-[#EFEFEF] hover:bg-gray-300 px-2 py-1 rounded-md z-20"
                      onClick={(e: any) => {
                        e.stopPropagation();
                      }}
                    >
                      <DownloadIcon />
                      <span className="text-[12px] font-medium ">
                        Download Template
                      </span>
                    </button>
                  </a>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      borderRadius: 8,
                      backgroundColor: "#000",
                      padding: "18px 20px",
                      fontSize: "13px",
                      // textDecoration:
                      textTransform: "none",
                      fontFamily: "AvenirLTProHeavy",
                      height: 20,
                    }}
                  >
                    Reupload
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col items-center justify-center h-full space-y-1">
                  <UploadIcon />
                  <h1 className="text-[#686767] text-[13px] ">
                    Drop file to upload or click
                  </h1>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      borderRadius: 8,
                      backgroundColor: "#000",
                      padding: "18px 36px",
                      fontSize: "17px",
                      // textDecoration:
                      textTransform: "none",
                      fontFamily: "AvenirLTProHeavy",
                      height: 40,
                    }}
                  >
                    Upload
                  </Button>
                  <h1 className="text-[11px]  text-center text-[#686767]">
                    (only Excel format)
                  </h1>

                  <a
                    href={
                      isTemplete
                        ? isTemplete
                        : require("../../assets/Templates/VoucherImport .xlsx")
                    }
                    download
                  >
                    <button
                      className="flex space-x-1 items-center justify-center bg-[#EFEFEF] hover:bg-gray-300 px-2 py-1 rounded-md z-20"
                      onClick={(e: any) => {
                        e.stopPropagation();
                      }}
                    >
                      <DownloadIcon />
                      <span className="text-[12px] font-medium ">
                        Download Template
                      </span>
                    </button>
                  </a>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="bg-white  rounded-md w-auto h-[128px]">
              {/* {Preview} */}
              <div className="flex items-center justify-center h-full space-x-2">
                <ExcelIcon className="w-6 h-6" />
                <h1 className="text-[14px]">
                  {isFile?.name || "uploaded.xlsx"}
                </h1>
              </div>
            </div>

            <div className="flex absolute bottom-1 items-center justify-end text-end space-x-4 right-1 ">
              <a
                href={
                  isTemplete
                    ? isTemplete
                    : require("../../assets/Templates/VoucherImport .xlsx")
                }
                download
              >
                <button
                  className="flex space-x-1 items-center justify-center bg-[#EFEFEF] hover:bg-gray-300 px-2 py-1 rounded-md z-20"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    console.log("first");
                  }}
                >
                  <DownloadIcon />
                  <span className="text-[12px] font-medium ">
                    Download Template
                  </span>
                </button>
              </a>
              <Button
                variant="contained"
                color="primary"
                style={{
                  borderRadius: 8,
                  backgroundColor: "#000",
                  padding: "18px 20px",
                  fontSize: "13px",
                  // textDecoration:
                  textTransform: "none",
                  fontFamily: "AvenirLTProHeavy",
                  height: 20,
                }}
              >
                Reupload
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UploadXslx;

// //  {isFile && (
//   <>
//   {acceptedFiles?.length > 0 ? (
//     <>
//       <div className="bg-white  rounded-md w-auto h-[128px]">
//         {/* {Preview} */}
//       </div>

//       <div className="flex absolute bottom-1 items-center justify-end text-end space-x-2 right-1 ">
//         <button
//           className="flex space-x-1 items-center justify-center bg-[#EFEFEF] hover:bg-gray-300 px-2 py-1 rounded-md z-20"
//           onClick={(e: any) => {
//             e.stopPropagation();
//             console.log("first");
//           }}
//         >
//           <DownloadIcon />
//           <span className="text-[12px] font-medium ">
//             Download Template
//           </span>
//         </button>
//         <h1 className="text-[13px]  bg-black text-white px-4 py-2 rounded-md select-none">
//           Reupload
//         </h1>
//       </div>
//     </>
//   ) : (
//     <>
//       <div className="bg-white  rounded-md w-auto h-[128px]">
//         <img
//           src={"preImage Link"}
//           className="object-cover block  w-auto h-full rounded-md"
//         />
//       </div>

//       <div className="flex absolute bottom-1 items-center justify-end text-end space-x-2 right-1 ">
//         <button
//           className="flex space-x-1 items-center justify-center bg-[#EFEFEF] hover:bg-gray-300 px-2 py-1 rounded-md z-20"
//           onClick={(e: any) => {
//             e.stopPropagation();
//             console.log("first");
//           }}
//         >
//           <DownloadIcon />
//           <span className="text-[12px] font-medium ">
//             Download Template
//           </span>
//         </button>
//         <h1 className="text-[13px]  bg-black text-white px-4 py-2 rounded-md select-none">
//           Reupload
//         </h1>
//       </div>
//     </>
//   )}
// </>
// )}
