import React from "react";
import KpiComponents from "../../Component/KpiComponents";
import { KPIMenuType } from "../../Typing";
import MembersIcons from "../../assets/LoyaltyMembersIcons//MembersIcons.svg";
import LoyaltyGrid from "./LoyaltyGrid";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { animated, useSpring } from "@react-spring/web";
import LoyaltyMemberUpload from "./LoyaltyMemberUpload";

type Props = {};
const KpiItems: KPIMenuType[] = [
  {
    ItemIcon: MembersIcons,
    ItemQuantity: 25,
    Title: "Civil Servants Club",
    Color: "#EFEFEF",
  },
  {
    ItemIcon: MembersIcons,
    ItemQuantity: 25,
    Title: "Club Members",
    Color: "#EFEFEF",
  },
  {
    ItemIcon: MembersIcons,
    ItemQuantity: 20,
    Title: "Pro Members",

    Color: "#EFEFEF",
  },
];
interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props,
  ref
) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

const style = {
  position: "absolute" as "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  borderRadius: 3,
  // border: "2px solid #000",
  boxShadow: 24,
  // cursor: "pointer",
};
const LoyaltyMembersIndex = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <LoyaltyMemberUpload _onCloseHandler={handleClose} />
          </Box>
        </Fade>
      </Modal>
      <div className="space-y-4 w-full px-4 relative">
        <div className="flex items-center justify-between">
          <h1 className="text-xl font-AvenirLTProHeavy">Loyalty Members</h1>
          <h1 className="text-sm font-AvenirLTProHeavy text-[#8B8B8B] flex">
            <span className="text-black flex items-center ">
              <div className="w-2 h-2 bg-black rounded-full mx-2" /> Members
            </span>
          </h1>
        </div>
        <KpiComponents
          KpiItems={KpiItems}
          clickText={"Upload"}
          _ModalOpenHanlder={() => setOpen(true)}
        />
        {/* Grid Page Component */}
        <div className="">
          <LoyaltyGrid />
        </div>
      </div>
    </div>
  );
};

export default LoyaltyMembersIndex;
