import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

interface UserLoginState {
  isLogin: boolean;
  accessToken: string;
  refreshToken: string;
}
const initValues = {
  isLogin: false,
  accessToken: "",
  refreshToken: "",
};
interface LoginSlice extends Slice<UserLoginState> {}

export const useLoginSlice: LoginSlice = createSlice({
  name: "userlogin",
  initialState: initValues,
  reducers: {
    USER_LOGIN: (state, action: PayloadAction<UserLoginState>) => {
      state.isLogin = action.payload.isLogin;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    USER_LOGOUT: (state) => {
      state.isLogin = false;
      state.accessToken = "";
      state.refreshToken = "";
    },
  },
});
// Action creators are generated for each case reducer function
export const { USER_LOGIN, USER_LOGOUT } = useLoginSlice.actions;

export default useLoginSlice.reducer;
