import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { getCommunicationStatics } from "../../apis/HomepageApis";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store";
import { StaticsData } from "../../Typing";
import { toast } from "react-toastify";

type Props = {};

const HomePageStatistics = (props: Props) => {
  const nav = useNavigate();
  const [options, setOptions] = useState<any>({
    series: [
      {
        name: "Active Sent",
        data: [0, 0, 0, 0],
      },
      {
        name: "Active Opened",
        data: [0, 0, 0, 0],
      },
      {
        name: "Upcoming",
        data: [0, 0, 0, 0],
      },
    ],
    chart: {
      type: "bar",
      height: 350,
      stacked: false,

      zoom: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        borderRadius: 10,
        borderRadiusApplication: "end",
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: any) {
        if (val != 0) {
          return val;
        }
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    stroke: {
      show: true,
      width: 4,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Cash", "Coupons", "Messages", "Events"],
      labels: {
        style: {
          colors: [],
          fontSize: "14px",
          fontFamily: "AvenirBlack, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-xaxis-label",
        },
      },
      axisBorder: {
        show: false, // Set show to false to hide the x-axis line
      },
    },

    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.3,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 1,
        stops: [0, 75, 100],
        colorStops: [],
      },
    },
    tooltip: {
      onDatasetHover: {
        highlightDataSeries: false,
      },
      y: {
        formatter: function (val: any) {
          return val;
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      fontSize: "14px",
      fontFamily: "AvenirBlack, Arial, sans-serif",
      fontWeight: 600,
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    grid: {
      strokeDashArray: [5, 5],
    },
    colors: [
      function ({ value, seriesIndex, dataPointIndex, w }: any) {
        if (seriesIndex === 0) {
          return "#3e4abd";
        }
        if (seriesIndex === 1) {
          return "#d1242b";
        }
        if (seriesIndex === 2) {
          return "#038374";
        }
      },
    ],
  });
  const handleClick = (events: any) => {
    const textContent = events?.target?.textContent;
    if (textContent === "Cash") {
      console.log("Cash");
      nav("/home/chartsGrid", { state: { name: "Cash", id: 0 } });
    }
    if (textContent === "Coupons") {
      nav("/home/chartsGrid", { state: { name: "Coupons", id: 1 } });
    }
    if (textContent === "Messages") {
      nav("/home/chartsGrid", { state: { name: "Messages", id: 2 } });
    }
    if (textContent === "Events") {
      nav("/home/chartsGrid", { state: { name: "Events", id: 3 } });
    }
  };
  const accessToken = useSelector(
    (state: RootState) => state.userLogin.accessToken
  );

  useEffect(() => {
    const getStaticsValues = async () => {
      const res = await getCommunicationStatics({
        accessToken: accessToken,
      });
      if (res.status === 200) {
        const StaticValues: StaticsData = res.data.data;
        const series = [
          {
            name: "Active Sent",
            data: [
              StaticValues.cash.active_sent,
              StaticValues.coupon.active_sent,
              StaticValues.message.active_sent,
              StaticValues.event.active_sent,
            ],
          },
          {
            name: "Active Opened",
            data: [
              StaticValues.cash.active_opened,
              StaticValues.coupon.active_opened,
              StaticValues.message.active_opened,
              StaticValues.event.active_opened,
            ],
          },
          {
            name: "Upcoming",
            data: [
              StaticValues.cash.upcoming,
              StaticValues.coupon.upcoming,
              StaticValues.message.upcoming,
              StaticValues.event.upcoming,
            ],
          },
        ];
        setOptions((prev: any) => {
          return { ...prev, series: series };
        });
      } else {
        toast.error("Something went wrong while getting static values!!");
      }
    };
    getStaticsValues();
  }, []);

  return (
    <div>
      <div className="text-xl font-AvenirBlack mt-2">
        Communication Statistics
      </div>
      <div className="border rounded-2xl bg-[#f9f9f9] mt-2">
        <Chart
          options={options}
          series={options?.series}
          type="bar"
          height={
            window.screen.height > 864
              ? window.screen.height * 0.5
              : window.screen.height * 0.4
          }
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default HomePageStatistics;
