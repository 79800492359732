import { Route, Routes } from "react-router-dom";
import Header from "../Component/Header";
import Sidebar from "../Sidebar/Sidebar";
import HomePage from "./HomePage/HomePage";
import LoyaltyMembersIndex from "./LoyaltyMembers/LoyaltyMembersIndex";
import HLCashIndex from "./Communication/HLCash/HLCashIndex";
import CouponsIndex from "./Communication/HLCoupon/CouponsIndex";
import HLMessage from "./Communication/HLMessage/HLMessage";
import TabIndex from "./HomePage/KpiTabs/TabIndex";
import ChartsGrid from "./HomePage/ChartsGrid/ChartsGrid";

type Props = {};

const UserPage = (props: Props) => {
  return (
    <div className="flex w-full h-screen relative ">
      <div>
        <Sidebar />
      </div>

      <div
        className={`flex-grow h-screen overflow-y-auto ease-in-out duration-300 `}
      >
        <div>
          <Header />
        </div>
        <div className="px-6 pt-4 ">
          <Routes>
            {/* Starting page */}
            <Route path="/" element={<HomePage />} />
            <Route path="/tabIndex" element={<TabIndex />} />
            <Route path="/home/chartsGrid" element={<ChartsGrid />} />

            {/* Loyalty Members */}
            <Route path="/loyalty_members" element={<LoyaltyMembersIndex />} />
            {/* Communication h&lCash*/}
            <Route path="/communication/h&lCash" element={<HLCashIndex />} />
            {/* Communication h&l Coupons start */}
            <Route
              path="/communication/h&lCoupons"
              element={<CouponsIndex />}
            />
            {/* Communication Messages */}
            <Route path="/communication/messages" element={<HLMessage />} />
            {/* Communication Events */}
            <Route path="/communication/events" element={<div></div>} />
            {/*  Brands */}
            <Route path="/brands" element={<div></div>} />
            {/*  Store */}
            <Route path="/stores" element={<div></div>} />
            {/* User Administration */}
            <Route path="/user_administration" element={<div></div>} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default UserPage;

// ${
//   isSideBarOpen ? "ml-[20.5rem]" : "ml-[6rem]"
// }
