import { useEffect, useRef, useState } from "react";
import TotalHNLMembers from "./TotalHNLMembers";
import CivilMembers from "./CivilMembers";
import ClubMembers from "./ClubMembers";
import ProMembers from "./ProMembers";
import { useLocation, useNavigate } from "react-router-dom";
import ItemCompnents from "../../../Component/GridPage/ItemCompnents";
import FiltersByData from "../../../Component/Filters/FiltersByData";
const tabsData = [
  {
    label: "Total Members",
    content: TotalHNLMembers,
  },
  {
    label: "Civil Members",
    content: CivilMembers,
  },
  {
    label: "Club Members",
    content: ClubMembers,
  },
  {
    label: "Pro Members",
    content: ProMembers,
  },
];
interface Props {}

const TabIndex = (props: Props) => {
  const location = useLocation();
  const nav = useNavigate();
  const postionValue = Number(location?.state?.id);
  if (Number.isNaN(postionValue)) {
    nav("/");
  }

  const [activeTabIndex, setActiveTabIndex] = useState(postionValue);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  const tabsRef = useRef<(HTMLButtonElement | null)[]>([]);

  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener("resize", setTabPosition);
    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);

  return (
    <div className="border-[1px] border-[#D7D4D4] p-4 rounded-lg">
      <div className="relative ">
        <div className="flex justify-between border-b-[1px] border-[#D7D4D4]">
          <div className="flex  space-x-6 ">
            {tabsData.map((tab, idx) => {
              return (
                <button
                  key={idx}
                  ref={(el) => (tabsRef.current[idx] = el)}
                  className={`pt-2 pb-3 text-lg font-AvenirLTProHeavy ${
                    activeTabIndex === idx ? "text-black" : "text-[#717171]"
                  } whitespace-pre `}
                  onClick={() => setActiveTabIndex(idx)}
                >
                  {tab.label}
                </button>
              );
            })}
          </div>
          <FiltersByData />
        </div>

        <span
          className="absolute bottom-0 block h-[5px] rounded-md bg-[#231f20] transition-all duration-300"
          style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
        />
      </div>
      <div className="py-4">
        {activeTabIndex === 0 && <TotalHNLMembers />}
        {activeTabIndex === 1 && <CivilMembers />}
        {activeTabIndex === 2 && <ClubMembers />}
        {activeTabIndex === 3 && <ProMembers />}
      </div>
    </div>
  );
};

export default TabIndex;
