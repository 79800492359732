import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { ReactComponent as ClockIcon } from "../assets/CouponsAssets/ModalIocns/ClockIcon.svg";
import moment from "moment";
import dayjs from "dayjs";
type Props = {
  _onClickHanlder: (e: any) => void;
  preValue?: string;
  isDisable?: boolean;
};

const TimePicker = ({ _onClickHanlder, preValue, isDisable }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div
      className={`flex flex-grow w-full border-[1px] border-[#A7A1A1] rounded-md ${
        isDisable && "bg-disable"
      }`}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="flex items-center justify-between flex-grow w-full pr-2">
          <MobileTimePicker
            disabled={isDisable}
            onClose={() => setIsOpen(false)}
            onOpen={() => setIsOpen(true)}
            open={isOpen}
            sx={{
              "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                display: "none",
                border: "none",
              },
              "& .MuiInputBase-root": {
                height: 39,
                fontSize: 14,
                borderRadius: 2,
                border: "none",
              },
              marginTop: 0.1,
              borderRadius: 1,

              "& fieldset": {
                borderBottomColor: "#d1d5db",
                border: "none",
              },
              "& label.Mui-focused": {
                borderBottomColor: "#d1d5db",
                border: "none",
              },
              // focused color for input with variant='standard'
              "& .MuiInput-underline:after": {
                borderBottomColor: "#d1d5db",
                border: "none",
              },
              // focused color for input with variant='filled'
              "& .MuiFilledInput-underline:after": {
                borderBottomColor: "#d1d5db",
                border: "none",
              },
              // focused color for input with variant='outlined'
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#d1d5db",
                  border: "none",
                },
              },
            }}
            defaultValue={preValue && dayjs(preValue)}
            onChange={(e: any) =>
              //moment(e.$d).format("HH:mm:ss")
              _onClickHanlder(moment(e.$d).format())
            }
          />
          <ClockIcon
            className="cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default TimePicker;
