import React from "react";
import { useLocation } from "react-router-dom";
import BgImg from "../assets/LoginAssets/bg_img.png";

import HlLogo from "../assets/LoginAssets/hl_white_logo.png";
import Login from "./Login";

type Props = {};

const LoginPage = (props: Props) => {
  
  return (
    <div className="flex">
      <div className="w-full grid grid-cols-1 md:grid-cols-5">
        <div className=" md:col-span-4 flex relative">
          <img
            src={BgImg}
            className={"md:w-screen md:h-screen object-cover brightness-50"}
            alt="React Logo"
          ></img>
        </div>
      </div>
      <div className="absolute w-full h-full  grid grid-cols-1 md:grid-cols-3">
        <div className="sm:col-span-2 ">
          <div className="flex items-center justify-center h-2/3 md:h-full  space-x-3 md:space-x-6">
            <img
              src={HlLogo}
              className={"select-none h-8 w-8 md:h-14 md:w-14 "}
              alt="Logo"
            />
            <h1 className="text-center  text-white text-2xl whitespace-nowrap md:text-4xl font-bold font-[AvenirLTProHeavy] select-none">
              Hardware & Lumber
            </h1>
          </div>
        </div>
        <div className=" -mt-[40%] md:mt-0 rounded-t-xl sm:rounded-l-2xl sm:rounded-none  bg-white z-10 ">
          <Login />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
