import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_API_URL;
export const deleteLoyaltyMember = ({
  accessToken,
  loyalty_no,
}: {
  accessToken: string;
  loyalty_no: number;
}) => {
  let config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: `${baseUrl}/admin/api/loyalty_member?loyalty_no=${loyalty_no}`,
    headers: {
      Authorization: `hle ${accessToken}`,
    },
  };

  const res = axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

  return res;
};

export const createLoyaltyMembersThroughExcl = ({
  accessToken,
  excL,
}: {
  accessToken: string;
  excL: any;
}) => {
  var data = new FormData();
  data.append("loyalty_member_excel", excL);

  const res = fetch(` ${baseUrl}/admin/api/loyalty_member/create`, {
    method: "POST",
    body: data,
    headers: {
      Authorization: `hlw ${accessToken}`,
    },
  })
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error;
    });
  return res;
};

export const activeInActiveToggle = ({
  accessToken,
  loyalty_no,
}: {
  accessToken: string;
  loyalty_no: number;
}) => {
  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${baseUrl}/admin/api/loyalty_member/?loyalty_no=${loyalty_no}`,
    headers: {
      Authorization: `hlw ${accessToken}`,
    },
  };

  const res = axios
    .request(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return res;
};
