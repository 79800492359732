import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const LoginApi = ({
  userName,
  password,
}: {
  userName: any;
  password: any;
}) => {
  var data = JSON.stringify({
    username: userName,
    password: password,
  });

  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/admin/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const res = axios(config)
    .then(function (response: any) {
      return response;
    })
    .catch(function (error: any) {
      return error;
    });
  return res;
};
// readCommunicationData
export const readCouponData = (url: string, token: string, filters?: any) => {
  var config = {
    method: "post",
    url: `${baseUrl}${url}`,
    headers: {
      Authorization: `hl ${token}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      filter: {
        ...filters,
      },
    }),
  };
  const res = axios(config).then(function (response) {
    return response;
  });

  return res;
};
export const createCoupon = (values: any, accessToken: string) => {
  var data = new FormData();
  data.append("title", values?.title);
  // data.append("coupon_excel", values?.coupon_excel);
  data.append("code", values?.code);
  data.append("brand_id", values?.brand_type_id);
  // data.append("membership_type_id", values?.membership_type_id);
  data.append("text", values?.text);
  data.append("image", values?.coupon_image);
  data.append("valid_from", values?.valid_from);
  data.append("valid_to", values?.valid_to);
  data.append("hidden_link_text", values?.hidden_link_text);
  data.append("instant_url_navigation", "false");
  data.append("publish_datetime", values?.publish_datetime);
  data.append("is_active", values?.is_active);
  data.append("push_message_text", values?.push_message_text);
  data.append("hidden_link_url", values?.hidden_link_url);
  data.append("loyalty_type_id", values?.loyalty_type?.code || null);
  data.append(
    "birth_month",
    values?.birth_month?.code ? values?.birth_month?.code : null
  );
  data.append("gender", values?.gender?.code ? values?.gender?.code : null);
  data.append("member_since", values?.member_since);
  data.append("parish", values?.parish?.code ? values?.parish?.code : null);
  data.append(
    "signup_store",
    values?.signup_store?.code ? values?.signup_store?.code : null
  );
  data.append(
    "anniversary_month",
    values?.anniversary_month?.code ? values?.anniversary_month?.code : null
  );

  console.log(data);

  const res = fetch(` ${baseUrl}/admin/api/coupon/create`, {
    method: "POST",
    body: data,
    headers: {
      Authorization: `hlw ${accessToken}`,
    },
  }).then((response) => {
    return response.json();
  });
  return res;
};

export const createCash = (values: any, accessToken: string) => {
  console.log(values);
  var data = new FormData();
  data.append("title", values?.title);
  data.append("excel", values?.excel);
  data.append("brand_id", values?.brand_id);
  data.append("loyalty_type_id", values?.loyalty_type);
  data.append("text", values?.text);
  data.append("image", values?.image);
  data.append("valid_from", values?.valid_from);
  data.append("valid_to", values?.valid_to);
  data.append("hidden_link_text", values?.hidden_link_text);
  data.append("instant_url_navigation", "false");
  data.append("publish_datetime", values?.publish_datetime);
  data.append("is_active", values?.is_active);
  data.append("push_message_text", values?.push_message_text);
  data.append("hidden_link_url", values?.hidden_link_url);

  const res = fetch(` ${baseUrl}/admin/api/cash/create`, {
    method: "POST",
    body: data,
    headers: {
      Authorization: `hlw ${accessToken}`,
    },
  }).then((response) => {
    return response;
  });
  return res;
};

export const deleteCashWithId = (id: string, accessToken: string) => {
  let config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: `${baseUrl}/admin/api/cash?id=${id}`,
    headers: {
      Authorization: `hnl ${accessToken}`,
    },
  };

  const res = axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return res;
};
export const deleteCouponWithId = (id: string, accessToken: string) => {
  let config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: `${baseUrl}/admin/api/coupon?code=${id}`,
    headers: {
      Authorization: `hnl ${accessToken}`,
    },
  };

  const res = axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return res;
};
export const deleteMessegeWithId = (id: string, accessToken: string) => {
  let config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: `${baseUrl}/admin/api/message?id=${id}`,
    headers: {
      Authorization: `hnl ${accessToken}`,
    },
  };

  const res = axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return res;
};

export const editCouponWithId = (values: any, accessToken: string) => {
  var data = new FormData();
  data.append("title", values?.title);
  data.append("code", values?.code);
  data.append("brand_id", values?.brand_type_id);
  // data.append("membership_type_id", values?.membership_type_id);
  data.append("text", values?.text);
  data.append("image", values?.coupon_image);
  data.append("valid_from", values?.valid_from);
  data.append("valid_to", values?.valid_to);
  data.append("hidden_link_text", values?.hidden_link_text);
  data.append("instant_url_navigation", "false");
  data.append("publish_datetime", values?.publish_datetime);
  data.append("is_active", values?.is_active);
  data.append("push_message_text", values?.push_message_text);
  data.append("hidden_link_url", values?.hidden_link_url);
  data.append(
    "loyalty_type_id",
    values?.loyalty_type?.code || values?.loyalty_type_id || null
  );
  data.append(
    "birth_month",
    values?.birth_month?.code ? values?.birth_month?.code : null
  );
  data.append("gender", values?.gender?.code ? values?.gender?.code : null);
  data.append(
    "member_since",
    values?.member_since ? values?.member_since : null
  );
  data.append("parish", values?.parish?.code ? values?.parish?.code : null);
  data.append(
    "signup_store",
    values?.signup_store?.code ? values?.signup_store?.code : null
  );
  data.append(
    "anniversary_month",
    values?.anniversary_month?.code ? values?.anniversary_month?.code : null
  );

  console.log(data);

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `hlw ${accessToken}`);
  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: data,
    redirect: "follow",
  };

  const res = fetch(
    ` ${baseUrl}/admin/api/coupon?code=${values?.code}`,
    //@ts-ignore
    requestOptions
  ).then((response) => {
    return response.json();
  });
  return res;
};
export const editCashWithId = (values: any, accessToken: string) => {
  var data = new FormData();
  data.append("title", values?.title);
  data.append("excel", values?.excel);
  // data.append("type_id", values?.type_id);
  data.append("brand_id", values?.brand_type_id);
  data.append("loyalty_type_id", values?.loyalty_type);
  data.append("text", values?.text);
  data.append("image", values?.image);
  data.append("valid_from", values?.valid_from);
  data.append("valid_to", values?.valid_to);
  data.append("hidden_link_text", values?.hidden_link_text);
  data.append("instant_url_navigation", "false");
  data.append("publish_datetime", values?.publish_datetime);
  data.append("is_active", values?.is_active);
  data.append("push_message_text", values?.push_message_text);
  data.append("hidden_link_url", values?.hidden_link_url);

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `hlw ${accessToken}`);
  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: data,
    redirect: "follow",
  };

  const res = fetch(
    ` ${baseUrl}/admin/api/cash?id=${values?.id}`,
    //@ts-ignore
    requestOptions
  ).then((response) => {
    return response;
  });
  return res;
};
export const readMessage = (url: string, token: string) => {
  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}${url}`,
    headers: {
      Authorization: `hl ${token}`,
    },
  };
  const res = axios(config).then(function (response) {
    return response;
  });

  return res;
};

export const FilterTarget = (values: any, accessToken: string) => {
  var data = new FormData();
  var myHeaders = new Headers();

  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `hlw ${accessToken}`);
  const res = fetch(` ${baseUrl}/admin/api/get_filtered_customer_count`, {
    method: "POST",
    body: JSON.stringify({
      loyalty_type_id: values?.loyalty_type?.code
        ? values?.loyalty_type?.code
        : values?.loyalty_type_id?.code
        ? values?.loyalty_type_id?.code
        : null,
      gender: values?.gender?.code ? values?.gender?.code : null,
      birth_month: values?.birth_month?.code ? values?.birth_month?.code : null,
      member_since: values?.member_since ? values?.member_since?.code : null,
      parish: values?.parish?.code ? values?.parish?.code : null,
      signup_store: values?.signup_store?.code
        ? values?.signup_store?.code
        : null,
      anniversary_month: values?.anniversary_month?.code
        ? values?.anniversary_month?.code
        : null,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `hlw ${accessToken}`,
    },
  }).then((response) => {
    return response.json();
  });
  return res;
};

export const createMessage = (values: any, accessToken: string) => {
  console.log(values?.hidden_link_url);
  var data = new FormData();
  data.append("title", values?.title);
  data.append("loyalty_type_id", values?.loyalty_type);
  data.append("gender", values?.gender);
  data.append("text", values?.text);
  data.append("image", values?.image);
  data.append("valid_from", values?.valid_from);
  data.append("valid_to", values?.valid_to);
  data.append("brand_id", values?.brand_type);
  data.append("hidden_link_text", values?.hidden_link_text);
  data.append("hidden_link_url", values?.hidden_link_url);
  data.append("publish_datetime", values?.publish_datetime);
  data.append("push_message_text", values?.push_message_text);
  data.append("instant_url_navigation", values?.instant_url_navigation);
  data.append("is_active", values?.is_active);

  console.log(data);

  const res = fetch(` ${baseUrl}/admin/api/message/create`, {
    method: "POST",
    body: data,
    headers: {
      Authorization: `hlw ${accessToken}`,
    },
  }).then((response) => {
    return response;
  });
  return res;
};

export const editMessage = (values: any, accessToken: string) => {
  console.log("called");
  var data = new FormData();
  data.append("title", values?.title);
  data.append("loyalty_type_id", values?.loyalty_type_id);
  data.append("gender", values?.gender);
  data.append("text", values?.text);
  data.append("image", values?.image);
  data.append("valid_from", values?.valid_from);
  data.append("valid_to", values?.valid_to);
  data.append("brand_id", values?.brand_type);
  data.append("hidden_link_text", values?.hidden_link_text);
  data.append("hidden_link_url", values?.hidden_link_url);
  data.append("publish_datetime", values?.publish_datetime);
  data.append("push_message_text", values?.push_message_text);
  data.append("instant_url_navigation", values?.instant_url_navigation);
  data.append("is_active", values?.is_active);

  const res = fetch(` ${baseUrl}/admin/api/message?id=${Number(values.id)}`, {
    method: "PUT",
    body: data,
    headers: {
      Authorization: `hlw ${accessToken}`,
    },
  }).then((response) => {
    return response;
  });
  return res;
};

export const isActiveAPi = (
  values: any,
  accessToken: string,
  apiLink: string
) => {
  console.log(values?.code);
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `hlw ${accessToken}`);

  var formdata = new FormData();
  formdata.append("is_active", values?.is_active);

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  const res = fetch(
    apiLink === "coupon"
      ? ` ${baseUrl}/admin/api/${apiLink}?code=${values?.code}`
      : ` ${baseUrl}/admin/api/${apiLink}?id=${values?.id}`,
    //@ts-ignore
    requestOptions
  ).then((response) => response.json());
  return res;
};
