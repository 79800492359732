import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ReactComponent as NotificationIcon } from "../assets/HeaderAssets/NotificationIcon.svg";
import { ReactComponent as Setting } from "../assets/HeaderAssets/Setting.svg";
import ProfileImage from "../assets/HeaderAssets/ProfileImg.png";
import { useDispatch } from "react-redux";
import { USER_LOGOUT } from "../Redux/LogInSlice";
import { useNavigate } from "react-router-dom";
type Props = {};

const Header = (props: Props) => {
  const route = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const _LogoutHandler = () => {
    route("/");
    dispatch(USER_LOGOUT(0));
  };
  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          marginTop: 0.5,
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={_LogoutHandler}>Logout</MenuItem>
      </Menu>
      <div className="h-[5rem] flex items-center justify-end w-full space-x-6 border-b-[1px] px-6">
        {/* Sets Items to the ends and  */}
        <div className=" relative">
          {/* Notification */}
          <div className="absolute w-4 h-4 rounded-full -right-[.3rem] -top-[0.3rem] bg-[#E22122] text-center text-white text-[12px] font-bold font-AvenirLTProHeavy">
            2
          </div>
          <NotificationIcon className="w-5 h-5 cursor-pointer" />
        </div>
        <Setting className="w-5 h-5 cursor-pointer" />
        {/* ProfileImage */}
        <button onClick={handleClick}>
          <img
            src={ProfileImage}
            alt="profileImage"
            className=" w-12 h-12  rounded-xl object-cover cursor-pointer"
          />
        </button>
      </div>
    </div>
  );
};

export default Header;
