import { Button } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as CloseModalIcon } from "../../../assets/CouponsAssets/ModalIocns/CloseModalIcon.svg";
import { RootState } from "../../../Redux/Store";
import { useAxiosRead } from "../../../CustomHook/useAxiosRead";
import { createCash } from "../../../apis/APIs";
import AutoComplete from "../../../Component/AutoComplete";
import DatePickerComponent from "../../../Component/DatePickerRe";
import UploadImage from "../../../Component/UploadsComponents/UploadImage";
import UploadXslx from "../../../Component/UploadsComponents/UploadXslx";
import TimePicker from "../../../Component/TimePicker";
import { toast } from "react-toastify";

type Props = {
  onClose: () => void;
};

const HLCashCreateModal = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNext, setIsNext] = useState(false);
  const accessToken = useSelector(
    (state: RootState) => state.userLogin.accessToken
  );
  const lList = useAxiosRead(
    "/admin/api/get_dropdown_options?key=3",
    accessToken
  );
  const lDropL = lList?.data?.map((item: any) => {
    return {
      label: item?.name,
      code: item?.id,
    };
  });
  const tList = useAxiosRead(
    "/admin/api/get_dropdown_options?key=2",
    accessToken
  );
  const tDropL = tList?.data?.map((item: any) => {
    return {
      label: item.name,
      code: item?.id,
    };
  });
  const bList = useAxiosRead(
    "/admin/api/get_dropdown_options?key=1",
    accessToken
  );
  const bDropL = bList?.data?.map((item: any) => {
    return {
      label: item?.name,
      code: item?.id,
    };
  });
  const [fromValues, setFromValues] = useState({
    title: "",
    // coupon_type_id: { label: "", code: "" },
    brand_id: { label: "", code: "" },
    loyalty_type_id: { label: "", code: "" },
    text: "",
    valid_from: "",
    valid_to: "",
    hidden_link_text: "",
    hidden_link_url: "",
    instant_url_text: "",
    is_active: true,
    push_message_text: "",
    image: null,
    excel: null,
    publish_date: "",
    publish_datetime: "",
    // barcode: "",
  });
  const onChangeInputHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFromValues((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const onChangeWithName = (name: string, value: any) => {
    setFromValues((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const _onSubmitHandler = async () => {
    setIsLoading(true);
    const values = {
      ...fromValues,
      // ["coupon_type_id"]: Number(fromValues.coupon_type_id?.code),
      ["brand_id"]: Number(fromValues.brand_id?.code),
      ["loyalty_type"]: Number(fromValues.loyalty_type_id?.code),
      ["publish_datetime"]:
        moment(fromValues.publish_date).format("YYYY-MM-DD") +
        "T" +
        moment(fromValues.publish_datetime).format("HH:mm:ssZ"),
    };

    const res: any = await createCash(values, accessToken);
    if (res?.status === 200) {
      toast.success("Data Inserted");
      props?.onClose();
    } else {
      toast.error("Something went wrong !!");
      props?.onClose();
    }
  };

  return (
    <div className="min-w-[50rem] ">
      {/* Title Bar */}
      <div className="px-6 bg-[#FFDBDB] min-h-[4rem] w-full flex items-center justify-between rounded-t-xl ">
        <h1 className=" text-center text-[18px] font-AvenirLTProHeavy">
          New H&L Cash Communication
        </h1>
        <CloseModalIcon
          onClick={props.onClose}
          className="cursor-pointer w-6 h-6"
        />
      </div>

      <div>
        {!isNext ? (
          <div>
            {/* Part-I parts */}
            <div className="px-6 grid grid-cols-3 py-3  gap-4">
              {/* Input Title */}
              <div className="flex flex-grow flex-col col-span-2">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                  Title
                </h1>
                <input
                  name={"title"}
                  value={fromValues.title}
                  onChange={onChangeInputHandler}
                  className="outline-none border-[#A7A1A1]  border-[1px] rounded-md  px-3 py-[.4rem]"
                />
              </div>
              {/* Select Type */}
              {/* <div>
                  <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                    Type
                  </h1>
                  <AutoComplete
                    onClick={(e) => onChangeWithName("coupon_type_id", e)}
                    data={tDropL}
                    preValue={fromValues.coupon_type_id}
                  />
                </div> */}
              {/* Loyalty Type */}
              <div>
                <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                  Loyalty Type
                </h1>

                <AutoComplete
                  onClick={(e) => onChangeWithName("loyalty_type_id", e)}
                  data={lDropL}
                  preValue={fromValues.loyalty_type_id}
                />
              </div>
              <div>
                <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                  Brands
                </h1>
                <AutoComplete
                  onClick={(e) => onChangeWithName("brand_id", e)}
                  data={bDropL}
                  preValue={fromValues.brand_id}
                />
              </div>
              {/* Select Type */}
              <div>
                <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                  Valid From
                </h1>
                <DatePickerComponent
                  preValue={fromValues.valid_from}
                  upDateValue={(e) => onChangeWithName("valid_from", e)}
                />
              </div>
              {/* Loyalty Type */}
              <div>
                <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                  Valid To
                </h1>
                <DatePickerComponent
                  preValue={fromValues.valid_to}
                  upDateValue={(e) => onChangeWithName("valid_to", e)}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 px-6 pt-2 pb-4 w-full gap-4">
              <div className="flex flex-col">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                  Hidden Link Title (text displayed in the app)
                </h1>
                <input
                  value={fromValues.hidden_link_text}
                  name={"hidden_link_text"}
                  onChange={onChangeInputHandler}
                  className="outline-none border-[#A7A1A1]  border-[1px] rounded-md  px-3 py-[.4rem] flex-grow"
                />
              </div>
              <div className="flex flex-col">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                  Hidden Link
                </h1>
                <input
                  placeholder="https://www.example.com/"
                  value={fromValues.hidden_link_url}
                  name={"hidden_link_url"}
                  className="outline-none border-[#A7A1A1]  border-[1px] rounded-md  px-3 py-[.4rem] flex-grow"
                  onChange={onChangeInputHandler}
                />
              </div>
              {/* <div className="-mt-1 flex space-x-2 items-start">
                <input
                  type="checkbox"
                  name={"instant_url_navigation"}
                  onChange={() => {
                    const name = "is_active";
                    const value = fromValues.is_active ? false : true;
                    onChangeWithName(name, value);
                  }}
                />
                <h1 className="text-[12px] font-AvenirLTProHeavy">
                  Instant URL navigation
                </h1>
              </div> */}
              <div className="col-span-2 w-full flex flex-col space-y-2">
                <h1 className="font-AvenirLTProHeavy text-[14px] ">
                  H&L Cash Communication Text
                </h1>
                <textarea
                  value={fromValues.text}
                  name={"text"}
                  onChange={onChangeInputHandler}
                  rows={5}
                  className="border-[#A7A1A1]    border-[1px] rounded-md flex-grow outline-none p-2"
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="px-6 grid grid-cols-2 py-3  gap-4">
              <div className="space-y-1">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px] ">
                  Upload Image
                </h1>
                {/* Upload Image */}

                <UploadImage
                  isFile={fromValues.image}
                  getFilePath={(e) => onChangeWithName("image", e)}
                />
              </div>
              <div className="space-y-1">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px] ">
                  Voucher Code
                </h1>
                {/* Upload Xslx */}
                <UploadXslx
                  isFile={fromValues.excel}
                  getFilePath={(e) => onChangeWithName("excel", e)}
                />
              </div>
            </div>
            <div className="px-6 grid grid-cols-3 pb-3  gap-4">
              <div className="w-full col-span-3 grid grid-cols-2 gap-3">
                {/* <div className="space-y-1 flex flex-col">
                    <h1 className="text-black font-AvenirLTProHeavy text-[13px] ">
                      Create Barcode
                    </h1>
                    <input
                      name={"barcode"}
                      className="outline-none  border-[#A7A1A1]  border-[1px] rounded-md  px-3 py-[.4rem] flex-grow"
                      onChange={onChangeInputHandler}
                    />
                  </div>
                  <div className="space-y-1 flex flex-col">
                    <h1 className="text-black font-AvenirLTProHeavy text-[13px] ">
                      Barcode Type
                    </h1>
                    <AutoComplete onClick={() => {}} data={[]} preValue="" />
                  </div> */}
              </div>
              <div className="space-y-1 flex flex-col">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px] ">
                  Publish Date
                  <span className="absolute -mt-[2px] text-red-500">*</span>
                </h1>
                <DatePickerComponent
                  preValue={fromValues.publish_date}
                  upDateValue={(e) => onChangeWithName("publish_date", e)}
                />
              </div>
              <div className="space-y-1 flex flex-col flex-grow">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px] ">
                  Time{" "}
                  <span className="absolute -mt-[2px] text-red-500">*</span>
                </h1>
                <TimePicker
                  preValue={fromValues.publish_datetime}
                  _onClickHanlder={(e) =>
                    onChangeWithName("publish_datetime", e)
                  }
                />
              </div>
              <div className="space-y-1 flex flex-col">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px] ">
                  Push Message
                </h1>
                <input
                  value={fromValues.push_message_text}
                  name={"push_message_text"}
                  className="outline-none  border-[#A7A1A1]  border-[1px] rounded-md  px-3 py-[.4rem] flex-grow"
                  onChange={onChangeInputHandler}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="border-[##C3C3C3] border-t border-[.5px]" />
      <div className="px-6 pb-3 space-x-3 py-3 flex justify-end">
        <button
          onClick={props.onClose}
          className="px-8 py-1 border-[#000000] border-[1px] rounded-md "
        >
          Cancel
        </button>
        {isNext && (
          <button
            onClick={() => setIsNext(false)}
            className="px-8 py-1 border-[#000000] border-[1px] rounded-md "
          >
            Back
          </button>
        )}

        {!isNext ? (
          <Button
            onClick={() => setIsNext(true)}
            variant="contained"
            color="primary"
            style={{
              borderRadius: 8,
              backgroundColor: "#000",
              padding: "18px 36px",
              fontSize: "15px",
              // textDecoration:
              textTransform: "none",
              // fontFamily: "AvenirLTProHeavy",
              height: 20,
            }}
          >
            Next
          </Button>
        ) : (
          <Button
            onClick={() => !isLoading && _onSubmitHandler()}
            variant="contained"
            color="primary"
            style={{
              borderRadius: 8,
              backgroundColor: "#000",
              padding: "18px 36px",
              fontSize: "15px",
              // textDecoration:
              textTransform: "none",
              // fontFamily: "AvenirLTProHeavy",
              height: 20,
            }}
          >
            {isLoading ? (
              <img
                src={require("../../../assets/loading.png")}
                className="w-6 h-6 animate-spin  "
                alt="loading"
              />
            ) : (
              "Save"
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default HLCashCreateModal;
