import axios from "axios";
import { useState, useEffect } from "react";
const baseUrl = "https://hl.mobileapp.distronix.in:3443";
export const useAxiosRead = (url: string, token: string) => {
  const [data, setData] = useState<any>();
  useEffect(() => {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}${url}`,
      headers: {
        Authorization: `hl ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setData(response.data);
      })
      .catch(function (error) {
        setData(error);
      });
  }, [url, token]);

  return data;
};
