import React, { useEffect, useState } from "react";
import CustomFilterComponents from "../../../Component/Filters/CustomFilterComponents";
import CustomGrid from "../../../Component/CustomGrid/CustomGrid";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/Store";
import { ToastContainer, toast } from "react-toastify";
import { getKpiGridValues } from "../../../apis/HomepageApis";
import { LoyaltyMember } from "../../../Typing";
type Props = {};

const ClubMembers = (props: Props) => {
  const [values, setValues] = useState<LoyaltyMember[]>([]);
  const accessToken = useSelector(
    (state: RootState) => state.userLogin.accessToken
  );

  useEffect(() => {
    const getTotalValues = async () => {
      const res = await getKpiGridValues({
        accessToken: accessToken,
        loyalty_type: 116,
      });
      setValues([]);
      if (res.status === 200) {
        setValues(res.data.loyalty_members);
      } else {
        toast.error("Something went wrong !");
      }
    };
    getTotalValues();
  }, []);

  const rows = [
    ...values?.map((item, index) => {
      return {
        key: item?.loyalty_number,
        id: index + 1,
        membershipNumber: item?.loyalty_number,
        loyaltyTypeName: item?.loyalty_type_name,
        name: item?.first_name + " " + item?.last_name,
        emailId: item?.email,
        mobileNo: item?.phone,
        memberCode: item?.customer_code,
        dob: item?.birthday,
        action: "",
      };
    }),
  ];

  const columns = [
    { id: "id", label: "Sr. No.", minWidth: 80, align: "left" },
    {
      id: "membershipNumber",
      label: "Membership Number",
      minWidth: 200,
      align: "left",
    },
    {
      id: "loyaltyTypeName",
      label: "Loyalty Type",
      minWidth: 160,
      align: "left",
    },
    { id: "name", label: "Name", minWidth: 230, align: "left" },
    {
      id: "emailId",
      label: "Email Id",
      minWidth: 160,
      align: "left",
    },
    {
      id: "mobileNo",
      label: "Mobile No.",
      minWidth: 160,
      align: "left",
    },
    {
      id: "memberCode",
      label: "Member Code",
      minWidth: 160,
      align: "left",
    },
    {
      id: "dob",
      label: "Date of Birth",
      minWidth: 160,
      align: "left",
    },
  ];

  return (
    <div>
      <ToastContainer />

      {/* Custom filter Start -----------*/}
      <CustomFilterComponents />
      {/* Custom filter End   -----------*/}

      {/* Grid start ------- */}
      <CustomGrid columns={columns} rows={rows} />
      {/* Grid end ------- */}
    </div>
  );
};

export default ClubMembers;
