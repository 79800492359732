import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as DeteteIcon } from "../../../assets/DeleteIcon.svg";
import { ReactComponent as ActiveIcon } from "../../../assets/ActiveIcon.svg";
import { ReactComponent as DuplicateIcon } from "../../../assets/DuplicateIcon.svg";
import { ReactComponent as ResendIcon } from "../../../assets/ResendIcon.svg";
import { ReactComponent as DeactiveIcon } from "../../../assets/DeactiveIcon.svg";
//Tost aleart
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import ModalIndex from "./ModalIndex";
import moment from "moment";
import DrawerComponet from "./DrawerComponet";

interface Column {
  id:
    | "id"
    | "Title"
    | "Brands"
    | "type"
    | "Published"
    | "fromDate"
    | "toDate"
    | "action";
  label: string;
  minWidth?: number;
  align?: "left" | "center";
}

const columns: Column[] = [
  { id: "id", label: "Sr. No.", minWidth: 80, align: "left" },
  { id: "Title", label: "Title", minWidth: 200, align: "center" },
  { id: "Brands", label: "Brands", minWidth: 170, align: "left" },
  {
    id: "type",
    label: "Loyalty Type",
    minWidth: 170,
    align: "left",
  },
  {
    id: "Published",
    label: "Published",
    minWidth: 120,
    align: "left",
  },
  {
    id: "fromDate",
    label: "From Date",
    minWidth: 120,
    align: "left",
  },
  {
    id: "toDate",
    label: "To Date",
    minWidth: 120,
    align: "left",
  },
  { id: "action", label: "Action", minWidth: 130, align: "center" },
];

const HLCouponGrid = ({ data, getData }: any) => {
  console.log(data);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showModal, setShowModal] = React.useState(false);
  const [modalValue, setModalValue] = React.useState(null);
  const [modalType, setModalType] = React.useState("");
  const [showDrawer, setShowDrawer] = React.useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const createData = (item: any) => {
    const Title = (
      <div>
        <h1
          className="underline decoration-blue-[#0061bb] text-[#0061bb] cursor-pointer "
          onClick={() => {
            setModalValue(item);
            setShowModal(true);
            setModalType("edit");
            // View and Edit Modal
          }}
        >
          {item?.title}
        </h1>
      </div>
    );
    const action = (
      <div className="flex space-x-[10px] justify-center ">
        <Tooltip title="Resend" placement="bottom">
          {/* Resend Modal should be open  */}
          <ResendIcon
            className="cursor-pointer"
            onClick={() => {
              setShowDrawer(true);
              setModalType("resend");
              setModalValue(item);
            }}
          />
        </Tooltip>
        <Tooltip title="Duplicate" placement="bottom">
          {/* Duplicate Modal should be open  */}
          <DuplicateIcon
            className="cursor-pointer"
            onClick={() => {
              setModalType("duplicate");
              setModalValue(item);
              setShowModal(true);
            }}
          />
        </Tooltip>
        {item?.is_active ? (
          <>
            <Tooltip title="Inactive" placement="bottom">
              {/* Call the inactive function */}
              <DeactiveIcon
                className="cursor-pointer"
                onClick={() => {
                  setShowDrawer(true);
                  setModalType("deactiveIcon");
                  setModalValue({
                    ...item,
                    code: item?.code,
                    id: item?.id,
                    is_active: false,
                  });
                }}
              />
            </Tooltip>
          </>
        ) : (
          <Tooltip title="Active" placement="bottom">
            {/* Call the inactive function */}
            <ActiveIcon
              className="cursor-pointer"
              onClick={() => {
                setShowDrawer(true);
                setModalType("activeIcon");
                setModalValue({
                  ...item,
                  code: item?.code,
                  id: item?.id,
                  is_active: true,
                });
              }}
            />
          </Tooltip>
        )}
        <Tooltip title="Delete" placement="bottom">
          {/* Call the delete function  */}
          <DeteteIcon
            className="cursor-pointer"
            onClick={() => {
              setShowDrawer(true);
              setModalType("delete");
              setModalValue(item);
            }}
          />
        </Tooltip>
      </div>
    );
    const valid_to = moment(item?.valid_to).format("DD-MM-YYYY");
    const valid_from = moment(item?.valid_from).format("DD-MM-YYYY");
    const publish_datetime = moment(item?.publish_datetime).format(
      "DD-MM-YYYY"
    );

    return {
      key: item?.id,
      id: item?.id,
      Brands: item?.brand_name,
      type: item?.loyalty_type_name,
      Published: publish_datetime,
      fromDate: valid_from,
      toDate: valid_to,
      Title,
      action,
      isActive: item?.is_active,
    };
  };

  const notify = () => toast.success("Wow so easy!");

  const rows = [...data?.map((item: any) => createData(item))];

  return (
    <div>
      <ToastContainer />
      {showModal && (
        <ModalIndex
          showModal={showModal}
          item={modalValue}
          onClose={() => {
            setShowModal(false);
            getData();
          }}
          modalType={modalType}
        />
      )}
      {showDrawer && (
        <DrawerComponet
          item={modalValue}
          onClose={() => {
            setShowDrawer(false);
            getData();
          }}
          type={modalType}
          showDrawer={showDrawer}
        />
      )}

      <Paper sx={{ width: "100%" }}>
        {/* <button onClick={notify}>Notify!</button>
        <ToastContainer /> */}
        <TableContainer
          sx={{
            maxHeight:
              window.screen.height > 864
                ? window.screen.height * 0.4
                : window.screen.height * 0.29,
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={uuidv4()}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#EEEEEE",
                      fontFamily: "AvenirLTProHeavy",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={uuidv4()}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={uuidv4()}
                            align={column.align}
                            style={{
                              backgroundColor: row?.isActive
                                ? "#fff"
                                : "#ebebeb",
                            }}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default HLCouponGrid;
