import React from "react";
import HomePageKPI from "./HomePageKPI";
import HomePageStatistics from "./HomePageStatistics";
import { getKpisRead } from "../../apis/HomepageApis";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store";
import { KPI_DATA_INTERFACE } from "../../Typing";
import { ToastContainer } from "react-toastify";

type Props = {};

const HomePage = (props: Props) => {
  const [kpiValues, setKpiValues] = React.useState<KPI_DATA_INTERFACE | null>(
    null
  );
  const accessToken = useSelector(
    (state: RootState) => state.userLogin.accessToken
  );

  const readKPIValues = async () => {
    const res = await getKpisRead(accessToken);

    if (res?.status === 200) {
      setKpiValues(res?.data.data);
    } else {
    }
  };
  React.useEffect(() => {
    readKPIValues();
  }, []);
  return (
    <div className="px-4 ">
      {/* className="px-6 font-medium font-AvenirBlack" */}
      <HomePageKPI kpiValues={kpiValues} />
      {/* Communication Statistics */}
      <HomePageStatistics />
    </div>
  );
};

export default HomePage;
