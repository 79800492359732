import { Button } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as CloseModalIcon } from "../../../assets/CouponsAssets/ModalIocns/CloseModalIcon.svg";
import { RootState } from "../../../Redux/Store";
import { useAxiosRead } from "../../../CustomHook/useAxiosRead";
import { FilterTarget, editCouponWithId } from "../../../apis/APIs";
import AutoComplete from "../../../Component/AutoComplete";
import DatePickerComponent from "../../../Component/DatePickerRe";
import UploadImage from "../../../Component/UploadsComponents/UploadImage";
import UploadXslx from "../../../Component/UploadsComponents/UploadXslx";
import TimePicker from "../../../Component/TimePicker";
import { ReactComponent as UserIcon } from "../../../assets/users.svg";
import { toast } from "react-toastify";

type Props = {
  onClose: () => void;
  item: any;
};

const HLCouponEditView = ({ onClose, item }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNext, setIsNext] = useState(false);
  const [count, setCount] = useState<any>("000");
  const accessToken = useSelector(
    (state: RootState) => state.userLogin.accessToken
  );

  const lList = useAxiosRead(
    "/admin/api/get_dropdown_options?key=3",
    accessToken
  );
  const lDropL = lList?.data?.map((item: any) => {
    return {
      label: item?.name,
      code: item?.id,
    };
  });
  const tList = useAxiosRead(
    "/admin/api/get_dropdown_options?key=4",
    accessToken
  );
  const tDropL = tList?.data?.map((item: any) => {
    return {
      label: item.name,
      code: item?.id,
    };
  });
  const bList = useAxiosRead(
    "/admin/api/get_dropdown_options?key=1",
    accessToken
  );
  const bDropL = bList?.data?.map((item: any) => {
    return {
      label: item?.name,
      code: item?.id,
    };
  });
  const signupList = useAxiosRead(
    "/admin/api/get_dropdown_options?key=5",
    accessToken
  );
  const signupDropdown = signupList?.data?.map((item: any) => {
    return {
      label: item?.name,
      code: item?.id,
    };
  });
  const [fromValues, setFromValues] = useState({
    title: item?.title,
    code: item?.code,
    coupon_type_id: {
      label: item?.coupon_type_name,
      code: item?.coupon_type_id,
    },
    brand_type_id: { label: item?.brand_name, code: item?.brand_id },

    text: item?.text,
    valid_from: item?.valid_from,
    valid_to: item?.valid_to,
    hidden_link_text: item?.hidden_link_text,
    hidden_link_url: item?.hidden_link_url,
    instant_url_text: "",
    is_active: item?.is_active,
    push_message_text: item?.push_message_text,
    coupon_image: item?.image_path,
    coupon_excel: "",
    publish_date: item?.publish_datetime,
    publish_datetime: item?.publish_datetime,
    id: item?.coupon_id,
    loyalty_type: {
      label: item?.loyalty_type_name,
      code: item?.loyalty_type_id,
    },
    gender: {
      label: item?.gender === "female" ? "Female" : "Male",
      code: item?.gender,
    },
    birth_month: {
      label:
        item?.birth_month === 1
          ? "January"
          : item?.birth_month === 2
          ? "February"
          : item?.birth_month === 3
          ? "March"
          : item?.birth_month === 4
          ? "April"
          : item?.birth_month === 5
          ? "May"
          : item?.birth_month === 6
          ? "June"
          : item?.birth_month === 7
          ? "July"
          : item?.birth_month === 8
          ? "August"
          : item?.birth_month === 9
          ? "September"
          : item?.birth_month === 10
          ? "October"
          : item?.birth_month === 11
          ? "November"
          : item?.birth_month === 12
          ? "December"
          : "Select Month",
      code: item?.birth_month,
    },
    anniversary_month: {
      label:
        item?.anniversary_month === 1
          ? "January"
          : item?.anniversary_month === 2
          ? "February"
          : item?.anniversary_month === 3
          ? "March"
          : item?.anniversary_month === 4
          ? "April"
          : item?.anniversary_month === 5
          ? "May"
          : item?.anniversary_month === 6
          ? "June"
          : item?.anniversary_month === 7
          ? "July"
          : item?.anniversary_month === 8
          ? "August"
          : item?.anniversary_month === 9
          ? "September"
          : item?.anniversary_month === 10
          ? "October"
          : item?.anniversary_month === 11
          ? "November"
          : item?.anniversary_month === 12
          ? "December"
          : "Select Month",
      code: item?.anniversary_month,
    },
    member_since: item?.member_since,
    parish: item?.parish
      ? {
          label: item?.parish_name,
          code: item?.parish_id,
        }
      : null,
    signup_store: item?.signup_store
      ? {
          label: item?.signup_store_name,
          code: item?.signup_store_id,
        }
      : null,
    // barcode: "",
  });

  const onChangeInputHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFromValues((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const onChangeWithName = (name: string, value: any) => {
    setFromValues((prev) => {
      return { ...prev, [name]: value };
    });
  };

  useEffect(() => {
    (fromValues.loyalty_type ||
      fromValues.gender ||
      fromValues.birth_month ||
      fromValues.anniversary_month ||
      fromValues.member_since ||
      fromValues.parish ||
      fromValues.signup_store) &&
      changeHandler();
  }, [
    fromValues.loyalty_type,
    fromValues.gender,
    fromValues.birth_month,
    fromValues.anniversary_month,
    fromValues.member_since,
    fromValues.parish,
    fromValues.signup_store,
  ]);

  const _onSubmitHandler = async () => {
    setIsLoading(true);
    const values = {
      ...fromValues,
      // ["coupon_type_id"]: Number(fromValues.coupon_type_id?.code),
      ["brand_type_id"]: Number(fromValues.brand_type_id?.code),
      ["loyalty_type_id"]: Number(fromValues.brand_type_id?.code),
      ["publish_datetime"]:
        moment(fromValues.publish_date).format("YYYY-MM-DD") +
        "T" +
        moment(fromValues.publish_datetime).format("HH:mm:ssZ"),
    };
    const res = await editCouponWithId(values, accessToken);
    if (res) {
      setIsLoading(false);
      console.log(res);
      if (res?.status === "Success") {
        toast.success("Coupon updated successfully!");
        onClose();
      } else {
        toast.error(res.message);
        // props?.onClose();
      }
    }
  };
  const pubDate = moment(item?.publish_datetime).format("YYYY-MM-DD");
  const toDate = moment(moment().format("YYYY-MM-DD"));
  const isDisable = toDate.diff(pubDate, "days") > 0;
  const month = [
    { label: "Select Month", code: null },
    { label: "January", code: 1 },
    { label: "February", code: 2 },
    { label: "March", code: 3 },
    { label: "April", code: 4 },
    { label: "May", code: 5 },
    { label: "June", code: 6 },
    { label: "July", code: 7 },
    { label: "August", code: 8 },
    { label: "September", code: 9 },
    { label: "October", code: 10 },
    { label: "November", code: 11 },
    { label: "December", code: 12 },
  ];
  const changeHandler = async () => {
    // console.log("called")
    const values = {
      ...fromValues,
    };
    const res = await FilterTarget(values, accessToken);
    if (res) {
      console.log(res);
      if (res.status === "Success") {
        setCount(res.count);
        // toast.success("Data Found!")
      } else {
        setCount("000");
        toast.error(res.message);
      }
    }
  };
  return (
    <div className="min-w-[50rem] ">
      {/* Title Bar */}
      <div className="px-6 bg-[#FFDBDB]  min-h-[4rem] w-full flex items-center justify-between rounded-t-xl ">
        <h1 className=" text-center text-[18px] font-AvenirLTProHeavy">
          {isDisable
            ? " View Coupon Communication"
            : " Edit Coupon Communication"}
        </h1>
        <CloseModalIcon onClick={onClose} className="cursor-pointer w-6 h-6" />
      </div>

      <div>
        {!isNext ? (
          <div>
            {/* Part-I parts */}
            <div className="px-6 grid grid-cols-3 py-3  gap-4">
              {/* Input Title */}
              <div className="flex flex-grow flex-col col-span-2">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                  Title
                </h1>
                <input
                  disabled={isDisable}
                  name={"title"}
                  value={fromValues.title}
                  onChange={onChangeInputHandler}
                  className={`outline-none border-[#A7A1A1]  border-[1px] rounded-md  px-3 py-[.4rem]
                  ${isDisable && "bg-disable text-disableText"}`}
                />
              </div>
              {/* Select Type */}
              {/* <div>
              <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                Type
              </h1>
              <AutoComplete
                onClick={(e) => onChangeWithName("coupon_type_id", e)}
                data={tDropL}
                preValue={fromValues.coupon_type_id}
              />
            </div> */}
              {/* Loyalty Type */}
              <div className="flex flex-grow flex-col">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                  Coupon Code
                </h1>

                <input
                  disabled={isDisable}
                  name={"code"}
                  onChange={onChangeInputHandler}
                  className={`outline-none border-[#A7A1A1]  border-[1px] rounded-md  px-3 flex-1 py-[.4rem]
                  ${isDisable && "bg-disable text-disableText"}`}
                  value={fromValues.code}
                />
              </div>
              <div>
                <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                  Brands
                </h1>
                <AutoComplete
                  onClick={(e) => onChangeWithName("brand_type_id", e)}
                  data={bDropL}
                  preValue={fromValues.brand_type_id}
                  isDisable={isDisable}
                />
              </div>
              {/* Select Type */}
              <div>
                <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                  Valid From
                </h1>
                <DatePickerComponent
                  preValue={fromValues.valid_from}
                  upDateValue={(e) => onChangeWithName("valid_from", e)}
                  isDisable={isDisable}
                />
              </div>
              {/* Loyalty Type */}
              <div>
                <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                  Valid To
                </h1>
                <DatePickerComponent
                  preValue={fromValues.valid_to}
                  upDateValue={(e) => onChangeWithName("valid_to", e)}
                  isDisable={isDisable}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 px-6 pt-2 pb-4 w-full gap-4">
              <div className="flex flex-col">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                  Hidden Link Title (text displayed in the app)
                </h1>
                <input
                  value={fromValues.hidden_link_text}
                  name={"hidden_link_text"}
                  onChange={onChangeInputHandler}
                  disabled={isDisable}
                  className={`outline-none border-[#A7A1A1]  border-[1px] rounded-md  px-3 py-[.4rem] flex-grow
                  ${isDisable && "bg-disable text-disableText"}`}
                />
              </div>
              <div className="flex flex-col">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                  Hidden Link
                </h1>
                <input
                  value={fromValues.hidden_link_url}
                  disabled={isDisable}
                  name={"hidden_link_url"}
                  className={`outline-none border-[#A7A1A1]  border-[1px] rounded-md  px-3 py-[.4rem] flex-grow
                  ${isDisable && "bg-disable text-disableText"}`}
                  onChange={onChangeInputHandler}
                  placeholder="https://www.example.com/"
                />
              </div>
              {/* <div className="-mt-1 flex space-x-2 items-start">
                <input
                  type="checkbox"
                  name={"instant_url_navigation"}
                  onChange={() => {
                    const name = "is_active";
                    const value = fromValues.is_active ? false : true;
                    onChangeWithName(name, value);
                  }}
                />
                <h1 className="text-[12px] font-AvenirLTProHeavy">
                  Instant URL navigation
                </h1>
              </div> */}
              <div className="col-span-2 w-full flex flex-col space-y-2">
                <h1 className="font-AvenirLTProHeavy text-[14px] ">
                  Coupon Text
                </h1>
                <textarea
                  value={fromValues.text}
                  name={"text"}
                  disabled={isDisable}
                  onChange={onChangeInputHandler}
                  rows={5}
                  className={`border-[#A7A1A1]    border-[1px] rounded-md flex-grow outline-none p-2
                  ${isDisable && "bg-disable text-disableText"}`}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="px-6 grid  py-3  gap-4">
              <div className="space-y-1">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px] ">
                  Upload Image
                </h1>
                {/* Upload Image */}

                <UploadImage
                  isFile={fromValues.coupon_image}
                  url={item?.image_path}
                  getFilePath={(e) => onChangeWithName("coupon_image", e)}
                  isDisable={isDisable}
                />
              </div>
            </div>
            {/* Filter Module start*/}
            <h1 className="px-6 py-1 font-AvenirLTProHeavy">Target Filters</h1>
            <div className="rounded-md mx-6 border-[1px] border-dashed border-[#5F5E5E]">
              <div className=" grid grid-cols-4 gap-4 p-3">
                <div>
                  <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                    Loyalty Type
                  </h1>

                  <AutoComplete
                    onClick={(e) => onChangeWithName("loyalty_type", e)}
                    data={lDropL}
                    isDisable={isDisable}
                    preValue={fromValues.loyalty_type}
                  />
                </div>
                <div>
                  <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                    Gender
                  </h1>

                  <AutoComplete
                    onClick={(e) => onChangeWithName("gender", e)}
                    data={[
                      { label: "Male", code: "male" },
                      { label: "Female", code: "female" },
                      { label: "Others", code: "others" },
                    ]}
                    isDisable={isDisable}
                    preValue={fromValues?.gender}
                  />
                </div>
                <div>
                  <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                    Birth Month
                  </h1>

                  <AutoComplete
                    onClick={(e) => onChangeWithName("birth_month", e)}
                    data={month}
                    isDisable={isDisable}
                    preValue={fromValues.birth_month}
                  />
                </div>
                <div>
                  <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                    Anniversary Month
                  </h1>

                  <AutoComplete
                    onClick={(e) => onChangeWithName("anniversary_month", e)}
                    data={month}
                    isDisable={isDisable}
                    preValue={fromValues.anniversary_month}
                  />
                </div>
                <div>
                  <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                    Member Since
                  </h1>
                  <DatePickerComponent
                    preValue={fromValues.member_since}
                    upDateValue={(e) => onChangeWithName("member_since", e)}
                    isDisable={isDisable}
                  />
                  {/* <AutoComplete
                    onClick={(e) => onChangeWithName("member_since", e)}
                    data={lDropL}
                    isDisable={isDisable}
                    preValue={fromValues.member_since}
                  /> */}
                </div>
                <div>
                  <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                    Parish
                  </h1>

                  <AutoComplete
                    onClick={(e) => onChangeWithName("parish", e)}
                    data={tDropL}
                    isDisable={isDisable}
                    preValue={fromValues.parish}
                  />
                </div>
                <div>
                  <h1 className="text-black font-AvenirLTProHeavy text-[13px]">
                    Sign-up Store
                  </h1>

                  <AutoComplete
                    onClick={(e) => onChangeWithName("signup_store", e)}
                    data={signupDropdown}
                    isDisable={isDisable}
                    preValue={fromValues.signup_store}
                  />
                </div>
              </div>
              <div className="bg-[#eeeded] mx-3 my-1 rounded-md p-2 flex space-x-2 items-center ">
                {/* Select number of users */}
                <UserIcon />
                <h1 className="font-AvenirLTProHeavy">
                  Total number of target members: {count}
                </h1>
              </div>
            </div>
            {/* Filter Module end*/}
            <div className=" px-6 grid grid-cols-3 pb-3  gap-4">
              <div className="w-full col-span-3 grid grid-cols-2 gap-3">
                {/* <div className="space-y-1 flex flex-col">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px] ">
                  Create Barcode
                </h1>
                <input
                  name={"barcode"}
                  className="outline-none  border-[#A7A1A1]  border-[1px] rounded-md  px-3 py-[.4rem] flex-grow"
                  onChange={onChangeInputHandler}
                />
              </div>
              <div className="space-y-1 flex flex-col">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px] ">
                  Barcode Type
                </h1>
                <AutoComplete onClick={() => {}} data={[]} preValue="" />
              </div> */}
              </div>
              <div className="space-y-1 flex flex-col">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px] ">
                  Publish Date
                  <span className="absolute -mt-[2px] text-red-500">*</span>
                </h1>
                <DatePickerComponent
                  preValue={fromValues.publish_date}
                  upDateValue={(e) => onChangeWithName("publish_date", e)}
                  isDisable={isDisable}
                />
              </div>
              <div className="space-y-1 flex flex-col flex-grow">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px] ">
                  Time{" "}
                  <span className="absolute -mt-[2px] text-red-500">*</span>
                </h1>
                <TimePicker
                  preValue={fromValues.publish_datetime}
                  _onClickHanlder={(e) =>
                    onChangeWithName("publish_datetime", e)
                  }
                  isDisable={isDisable}
                />
              </div>
              <div className="space-y-1 flex flex-col">
                <h1 className="text-black font-AvenirLTProHeavy text-[13px] ">
                  Push Message
                </h1>
                <input
                  value={fromValues.push_message_text}
                  name={"push_message_text"}
                  className={`outline-none  border-[#A7A1A1]  border-[1px] rounded-md  px-3 py-[.4rem] flex-grow
                  ${isDisable && "bg-disable text-disableText"}`}
                  onChange={onChangeInputHandler}
                  disabled={isDisable}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="border-[##C3C3C3] border-t border-[.5px]" />
      <div className="px-6 pb-3 space-x-3 py-3 flex justify-end">
        <button
          onClick={onClose}
          className="px-8 py-1 border-[#000000] border-[1px] rounded-md "
        >
          Cancel
        </button>
        {isNext && (
          <button
            onClick={() => setIsNext(false)}
            className="px-8 py-1 border-[#000000] border-[1px] rounded-md "
          >
            Back
          </button>
        )}

        {!isNext ? (
          <Button
            onClick={() => setIsNext(true)}
            variant="contained"
            color="primary"
            style={{
              borderRadius: 8,
              backgroundColor: "#000",
              padding: "18px 36px",
              fontSize: "15px",
              // textDecoration:
              textTransform: "none",
              // fontFamily: "AvenirLTProHeavy",
              height: 20,
            }}
          >
            Next
          </Button>
        ) : (
          <div>
            {!isDisable && (
              <Button
                onClick={() => !isLoading && _onSubmitHandler()}
                variant="contained"
                color="primary"
                style={{
                  borderRadius: 8,
                  backgroundColor: "#000",
                  padding: "18px 36px",
                  fontSize: "15px",
                  // textDecoration:
                  textTransform: "none",
                  // fontFamily: "AvenirLTProHeavy",
                  height: 20,
                }}
              >
                {isLoading ? (
                  <img
                    src={require("../../../assets/loading.png")}
                    className="w-6 h-6 animate-spin  "
                    alt="loading"
                  />
                ) : (
                  "Update"
                )}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HLCouponEditView;
