import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import HLMemberBg from "../../assets/HomePageIcons/kpi1.svg";
import CivilMemberIcon from "../../assets/HomePageIcons/MemberIocns.svg";
import ClubMemberIcon from "../../assets/HomePageIcons/ClubMeberIcons.svg";
import ProMemberIcon from "../../assets/HomePageIcons/ProMemberIcons.svg";
import "./HomeStyle.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate } from "react-router-dom";
import { KPI_DATA_INTERFACE, KPIItemType } from "../../Typing";

type Props = {
  kpiValues: KPI_DATA_INTERFACE | null;
};

const HomePageKPI = ({ kpiValues }: Props) => {
  const nav = useNavigate();
  const [kpiItem, setKpiItem] = useState<KPIItemType[]>([]);

  useEffect(() => {
    if (kpiValues) {
      const newKpiItem: KPIItemType[] = [
        {
          title: "H&L Members",
          count: kpiValues?.all?.total_members || 0,
          appusers: kpiValues?.all?.total_app_users || 0,
          img: HLMemberBg,
          color: "#e31f26",
          barBgColor: "#f09195",
          barColor: "#fff",
          progressBarColor: "#f09195",
          progressBarBg: "#ffffff",
          params: "totalMembers",
          path: "/tabIndex",
        },
        {
          title: "Civil Member",
          count: kpiValues?.civil?.total_members || 0,
          appusers: kpiValues?.civil?.total_app_users || 0,
          icon: CivilMemberIcon,
          color: "#ffe8e8",
          barBgColor: "#fff",
          barColor: "#f87f7f",
          progressBarColor: "#f87f7f",
          progressBarBg: "#ffffff",
          params: "civilMembers",
          path: "/tabIndex",
        },
        {
          title: "Club Members",
          count: kpiValues?.club.total_members || 0,
          appusers: kpiValues?.club.total_app_users || 0,
          icon: ClubMemberIcon,
          color: "#d7ffe7",
          barBgColor: "#fff",
          barColor: "#80b997",
          progressBarColor: "#80b997",
          progressBarBg: "#ffffff",
          params: "clubMembers",
          path: "/tabIndex",
        },
        {
          title: "Pro Members",
          count: kpiValues?.pro?.total_members || 0,
          appusers: kpiValues?.pro?.total_app_users || 0,
          icon: ProMemberIcon,
          color: "#eaf2ff",
          barBgColor: "#fff",
          barColor: "#8499bb",
          progressBarColor: "#8499bb",
          progressBarBg: "#ffffff",
          params: "proMembers",
          path: "/tabIndex",
        },
      ];
      setKpiItem(newKpiItem);
    }
  }, [kpiValues]);

  return (
    <div className="">
      <div className="text-xl  font-AvenirBlack">Loyalty Members</div>
      <div className="grid grid-cols-4 gap-4 py-2">
        {kpiItem?.map((item, index) => (
          <div
            key={uuid()}
            style={{
              backgroundColor: item?.color,
            }}
            className={`h-36 rounded-2xl px-4 py-3 cursor-pointer shadow-md ${
              item?.img && "bgImage"
            }`}
            onClick={() =>
              nav(item.path, { state: { id: index, name: item?.params } })
            }
          >
            {/* Top Area */}
            <div className="flex  justify-between">
              <div>
                {/* Text vlaues */}
                <h1
                  className={` text-2xl font-AvenirLTProHeavy ${
                    index === 0 ? "text-white" : "text-black"
                  }`}
                >
                  {item?.title}
                </h1>
                <h1
                  className={`${
                    index === 0 ? "text-white" : "text-black"
                  } text-3xl  font-AvenirBlack`}
                >
                  {item?.count}
                </h1>
              </div>
              {item?.icon && (
                <div>
                  <div className="bg-white flex items-center justify-center rounded-full w-14 h-14 shadow-md">
                    <img src={item?.icon} className="" />
                  </div>
                </div>
              )}
            </div>
            {/* Buttom Area  */}
            <div>
              <h1
                className={`${
                  index === 0 ? "text-white" : "text-black"
                } font-AvenirLTProHeavy`}
              >
                App user
                <span>
                  {" "}
                  {item?.appusers} (
                  {Number.isNaN((item?.appusers / item?.count) * 100)
                    ? 0
                    : ((item?.appusers / item?.count) * 100).toFixed(0)}
                  %)
                </span>
              </h1>
              <div className="pt-2 ">
                <ProgressBar
                  completed={
                    Number.isNaN((item?.appusers / item?.count) * 100)
                      ? 0
                      : (item?.appusers / item?.count) * 100
                  }
                  height={"10px"}
                  baseBgColor={item?.progressBarBg}
                  isLabelVisible={false}
                  bgColor={item?.progressBarColor}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePageKPI;
