import React, { useState, SyntheticEvent, useEffect } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
// calender Import
//@ts-ignore
import { Calendar } from "react-date-range";
import { Menu } from "@mui/material";
import { ReactComponent as CalenderIcon } from "../assets/CalenderIcon.svg";
type Props = {
  upDateValue?: (value: string) => void;
  preValue?: string;
  isDisable?: boolean;
  disableForwards?: boolean;
  disableBackwards?: boolean;
};

const DatePickerComponent = ({
  upDateValue,
  preValue,
  isDisable,
  disableBackwards,
  disableForwards,
}: Props) => {
  const [date, setDate] = useState<Date>();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [selectDate, setSelectDate] = useState<string | null>(null);
  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropdownClose = (url?: string) => {
    if (url) {
      // router.push(url)
    }
    setAnchorEl(null);
  };

  const onChangeHandler = (item: Date) => {
    setDate(item);
    setAnchorEl(null);
    setSelectDate(moment(item).format("DD.MM.YYYY"));
    upDateValue && upDateValue(moment(item).format());
  };

  // useEffect(() => {
  //   upDateValue && upDateValue(moment(date).format());
  // }, [date]);

  return (
    <>
      <div
        onClick={(e) => !isDisable && handleDropdownOpen(e)}
        className={`border-[1px]  flex-grow w-full  border-[#A7A1A1] rounded-md h-[2.4rem] flex items-center justify-between px-4 min-w-[150px] cursor-pointer ${
          isDisable && "bg-disable cursor-not-allowed text-disableText"
        }`}
      >
        <h1
          className={`select-none text-[16px] mx-1 text-black ${
            isDisable && "text-disableText"
          }`}
        >
          {preValue
            ? moment(preValue).format("DD.MM.YYYY")
            : preValue === ""
            ? null
            : selectDate}
        </h1>
        <CalenderIcon />
      </div>
      <Menu
        onClose={() => handleDropdownClose()}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        sx={{
          "& .MuiMenu-paper": {
            width: 330,
            borderRadius: 4,
            alignItems: "center",
          },
        }}
      >
        <Calendar
          className="bg-black"
          date={date}
          onChange={(item) => {
            onChangeHandler(item);
          }}
          minDate={
            disableBackwards ? new Date() : new Date(new Date().getFullYear())
          }
          maxDate={!disableForwards ? new Date("2039-12-31") : new Date()}
        />
      </Menu>
    </>
  );
};

export default DatePickerComponent;
