import React, { useState } from "react";
import { ReactComponent as MenuToggle } from "../assets/SidebarAssets/MenuToggle.svg";
import RedPNGLogo from "../assets/SidebarAssets/RedPNGLogo.png";
import HomeIcon from "../assets/SidebarAssets/HomeIcon.svg";
import Loyalty from "../assets/SidebarAssets/Loyalty.svg";
import Communication from "../assets/SidebarAssets/Communication.svg";
import ArrowDown from "../assets/SidebarAssets/ArrowDown.svg";
import Brands from "../assets/SidebarAssets/Brands.svg";
import Stores from "../assets/SidebarAssets/Stores.svg";
import server from "../assets/SidebarAssets/server.svg";
import { MenuItemsType } from "../Typing";
import { useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
type Props = {
  _sidebarStateControl: (value: boolean) => void;
};
const MenuItems: MenuItemsType[] = [
  {
    id: 1,
    icon: HomeIcon,
    title: "Home",
    isSub: false,
    subItems: [],
    path: "/",
  },
  {
    id: 2,
    icon: Loyalty,
    title: "Loyalty Members",
    isSub: false,
    subItems: [],
    path: "/loyalty_members",
  },
  {
    id: 3,
    icon: Communication,
    title: "Communication",
    isSub: true,
    subItems: [
      {
        id: 1,
        title: "H&L Cash",
        path: "/communication/h&lCash",
      },
      { id: 2, title: "H&L Coupons", path: "/communication/h&lCoupons" },
      { id: 3, title: "Messages", path: "/communication/messages" },
      { id: 4, title: "Events", path: "/communication/events" },
    ],
  },
  {
    id: 4,
    icon: Brands,
    title: "Brands",
    isSub: false,
    subItems: [],
    path: "/brands",
  },
  {
    id: 5,
    icon: Stores,
    title: "Stores",
    isSub: false,
    subItems: [],
    path: "/stores",
  },
  {
    id: 6,
    icon: server,
    title: "User Administration",
    isSub: false,
    subItems: [],
    path: "/user_administration",
  },
];

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState<MenuItemsType>();
  const nav = useNavigate();
  const location = useLocation();

  return (
    <div
      className={`flex flex-col bg-SidebarNormal ${
        isOpen ? "w-[10rem] md:w-[20.5rem]" : "w-[5rem] md:w-[6rem]"
      }  h-full transform ease-in-out duration-300   `}
    >
      <div
        className="absolute -right-[2.2rem]  top-7 rounded-full cursor-pointer "
        onClick={() => {
          setIsOpen(!isOpen);
          setIsSubMenuOpen(undefined);
        }}
      >
        {/* Menu Toggle Icon */}
        <MenuToggle className="w-14 h-14 " />
      </div>
      <div className=" py-8  px-8  ">
        {/* Logo With text */}
        <div className="flex items-center text-center  space-x-2">
          <img src={RedPNGLogo} className="w-10 h-10 " />
          <h1
            className={`text-white font-[AvenirBlack] text-[1.4rem] ${
              !isOpen && "hidden"
            } select-none truncate`}
          >
            Hardware & Lumber
          </h1>
        </div>
      </div>
      <div className="px-4 pt-4   h-screen overflow-y-auto">
        {/* //overflow-hidden overflow-y-scroll */}

        {/* ==================================== Menu Items */}

        {MenuItems.map((item, index) => {
          return (
            <div className="select-none" key={index}>
              <div
                className={`flex items-center justify-between my-2 p-4 rounded-md cursor-pointer  hover:bg-SidebarActive  ${
                  location?.pathname === item?.path ? "bg-SidebarActive" : ""
                }`}
                onClick={() => {
                  item?.path && nav(item?.path);
                  setIsOpen(true);
                  if (item?.isSub) {
                    if (isSubMenuOpen) {
                      setIsSubMenuOpen(undefined);
                    } else setIsSubMenuOpen(item);
                  }
                }}
              >
                <div className="flex space-x-4 items-center">
                  <img src={item?.icon} alt={item?.icon} className="w-6 h-6" />
                  {isOpen && (
                    <h1 className="text-white text-[1rem] font-[AvenirLTProHeavy] truncate font-[400]">
                      {item?.title}
                    </h1>
                  )}
                </div>
                {item?.isSub && isOpen && (
                  <img
                    src={ArrowDown}
                    className={`transform ${
                      isSubMenuOpen && isSubMenuOpen?.title === item.title
                        ? "rotate-0 "
                        : "rotate-180"
                    } duration-300 `}
                    alt="Arrow"
                  />
                )}
              </div>

              {/* SumMenu ======------------ */}

              {isSubMenuOpen?.title === item?.title &&
                isSubMenuOpen?.subItems.map((item, index) => (
                  <AnimatePresence key={index}>
                    <motion.div
                      initial={{
                        y: "100%",
                        opacity: 0,
                        scale: 1,
                      }}
                      animate={{ y: "0%", opacity: 1, scale: 1 }}
                      exit={{ y: "50%", opacity: 0 }}
                      transition={{ duration: 0.3, ease: "easeOut" }}
                      className={`flex w-full`}
                    >
                      <div className=" border-[#868686] border-[1px] ml-7" />
                      <div
                        onClick={() => {
                          nav(item?.path);
                        }}
                        id={item.title}
                        className={`px-4 py-2 my-1 mx-2 hover:bg-SidebarActive rounded-md cursor-pointer ${
                          location?.pathname === item?.path
                            ? "bg-SidebarActive"
                            : ""
                        } w-full`}
                      >
                        <h1 className="text-white text-[1rem]  font-[AvenirLTProHeavy] select-none truncate">
                          {item?.title}
                        </h1>
                      </div>
                    </motion.div>
                  </AnimatePresence>
                ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
