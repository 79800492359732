import React, { useEffect, useState } from "react";
import CustomGrid from "../../Component/CustomGrid/CustomGrid";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store";
import { getKpiGridValues } from "../../apis/HomepageApis";
import { ToastContainer, toast } from "react-toastify";
import CustomFilterComponents from "../../Component/Filters/CustomFilterComponents";
import { ReactComponent as DeteteIcon } from "../../assets/DeleteIcon.svg";
import { ReactComponent as DeactiveIcon } from "../../assets/DeactiveIcon.svg";
import { ReactComponent as ActiveIcon } from "../../assets/ActiveIcon.svg";

import Tooltip from "@mui/material/Tooltip";
import DrawerComponentsLM from "./DrawerComponentsLM";
import { readCouponData } from "../../apis/APIs";

type Props = {};
const columns = [
  { id: "id", label: "Sr. No.", minWidth: 80, align: "left" },
  {
    id: "membershipNumber",
    label: "Membership Number",
    minWidth: 200,
    align: "left",
  },
  {
    id: "loyaltyTypeName",
    label: "Loyalty Type",
    minWidth: 160,
    align: "left",
  },
  { id: "name", label: "Name", minWidth: 230, align: "left" },
  {
    id: "emailId",
    label: "Email Id",
    minWidth: 160,
    align: "left",
  },
  {
    id: "mobileNo",
    label: "Mobile No.",
    minWidth: 160,
    align: "left",
  },
  {
    id: "memberCode",
    label: "Member Code",
    minWidth: 160,
    align: "left",
  },
  {
    id: "dob",
    label: "Date of Birth",
    minWidth: 130,
    align: "left",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 140,
    align: "center",
  },
];

const LoyaltyGrid = (props: Props) => {
  const [values, setValues] = useState<any[]>([]);
  const [editValues, setEditValues] = useState<any>(null);
  const [drawerFor, setDrawerFor] = useState<string>("");
  const accessToken = useSelector(
    (state: RootState) => state.userLogin.accessToken
  );
  const getTotalValues = async (filetrs?: any) => {
    const res = await readCouponData(
      "/admin/api/loyalty_member",
      accessToken,
      filetrs
    );
    if (res.status === 200) {
      setValues(res.data.loyalty_members);
    } else {
      toast.error("Something went wrong while getting Total H&L Members!!");
    }
  };
  useEffect(() => {
    getTotalValues();
  }, []);
  const rows = [
    ...values?.map((item, index) => {
      const action = (
        <div className="flex space-x-[10px] justify-center ">
          {item?.is_active ? (
            <>
              <Tooltip title="Inactive" placement="bottom">
                {/* Call the inactive function */}
                <DeactiveIcon
                  className="cursor-pointer"
                  onClick={() => {
                    setEditValues(item);
                    setDrawerFor("inactive");
                  }}
                />
              </Tooltip>
            </>
          ) : (
            <Tooltip title="Active" placement="bottom">
              {/* Call the inactive function */}
              <ActiveIcon
                className="cursor-pointer"
                onClick={() => {
                  setEditValues(item);
                  setDrawerFor("active");
                }}
              />
            </Tooltip>
          )}

          <Tooltip title="Delete" placement="bottom">
            {/* Call the delete function  */}
            <DeteteIcon
              className="cursor-pointer"
              onClick={() => {
                setEditValues(item);
                setDrawerFor("delete");
              }}
            />
          </Tooltip>
        </div>
      );
      return {
        key: item?.loyalty_number,
        id: index + 1,
        membershipNumber: item?.loyalty_number,
        loyaltyTypeName: item?.loyalty_type_name,
        name: item?.first_name + " " + item?.last_name,
        emailId: item?.email,
        mobileNo: item?.phone,
        memberCode: item?.customer_code,
        dob: item?.birthday,
        action: action,
      };
    }),
  ];
  const gWidth =
    window.screen.height > 864
      ? window.screen.height * 0.4
      : window.screen.height * 0.35;

  const drawerCloseHandler = () => {
    setEditValues(null);
    getTotalValues();
  };
  const _onFilterBtnHandler = (filetrs: any) => {
    getKpiGridValues(filetrs);
  };
  return (
    <div>
      <ToastContainer />
      {/* Drawer for delete and inactive active */}
      {editValues && (
        <DrawerComponentsLM
          value={editValues}
          drawerType={drawerFor}
          onClose={drawerCloseHandler}
        />
      )}
      {/* Custom filter Start -----------*/}
      <CustomFilterComponents onClick={_onFilterBtnHandler} />
      {/* Custom filter End   -----------*/}
      <CustomGrid columns={columns} rows={rows} gWidth={gWidth} />
    </div>
  );
};

export default LoyaltyGrid;
