import React, { useEffect, useState } from "react";
import CustomGrid from "../../../Component/CustomGrid/CustomGrid";
import { getCommunicationStatics } from "../../../apis/HomepageApis";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/Store";
import { HomePageStaticGridValuesStype } from "../../../Typing";
type Props = {};

const MessagesCommunication = (props: Props) => {
  const accessToken = useSelector(
    (state: RootState) => state.userLogin.accessToken
  );
  const [cashes, setCashes] = useState<HomePageStaticGridValuesStype[]>([]);
  useEffect(() => {
    const getStaticsValues = async () => {
      const res = await getCommunicationStatics({
        accessToken: accessToken,
        comm_type: "message",
      });
      if (res.status === 200) {
        setCashes(res.data.data);
      } else {
        toast.error("Something went wrong while getting static values!!");
      }
    };
    getStaticsValues();
  }, []);
  const rows = [
    ...cashes?.map((item) => {
      const perCent = Number.isNaN(
        Number(item?.opened_count) / Number(item?.sent_count)
      )
        ? 0
        : Number(item?.opened_count) / Number(item?.sent_count);

      return {
        key: item?.id,
        id: item?.id,
        title: item?.title,
        sent: item?.sent_count,
        open: item?.opened_count,
        avgOpen: perCent * 100 + "%",
      };
    }),
  ];

  const columns = [
    { id: "id", label: "Sr. No.", minWidth: 80, align: "left" },
    { id: "title", label: "Title", minWidth: 160, align: "left" },
    { id: "sent", label: "Messages", minWidth: 160, align: "left" },
    { id: "open", label: "Open", minWidth: 160, align: "left" },
    {
      id: "avgOpen",
      label: "Average Open Rate %",
      minWidth: 160,
      align: "left",
    },
  ];
  return (
    <div>
      <ToastContainer />

      <CustomGrid columns={columns} rows={rows} />
    </div>
  );
};

export default MessagesCommunication;
