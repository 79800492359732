import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
import { forwardRef } from "react";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarAlert = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState<string>("");
  const [variant, setVariant] = React.useState<AlertColor | undefined>();
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  React.useImperativeHandle(ref, () => ({
    _showTost(msg: string, variant: AlertColor) {
      setOpen(true);
      setMsg(msg);
      setVariant(variant);
    },
  }));

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert onClose={handleClose} severity={variant} sx={{ width: "100%" }}>
        {msg}
      </Alert>
    </Snackbar>
  );
});
export default SnackbarAlert;
