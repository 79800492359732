import { KPIMenuType } from "../Typing";
import CreateIcon from "../assets/CouponsAssets/CreateIcon.svg";
//@ts-ignore
import { v4 as uuidv4 } from "uuid";
type Props = {
  KpiItems: KPIMenuType[];
  clickText: string;
  _ModalOpenHanlder: () => void;
};

const KpiComponents = ({ KpiItems, clickText, _ModalOpenHanlder }: Props) => {
  return (
    <div className="grid grid-cols-4 gap-6">
      {/* Kpi items */}
      {KpiItems?.map((item) => (
        <div
          className={` h-[100px] rounded-2xl  flex items-center justify-between px-6 cursor-pointer`}
          key={uuidv4()}
          style={{ backgroundColor: item?.Color }}
        >
          <img src={item?.ItemIcon} alt="" />
          <div className="flex flex-col items-end justify-end ">
            <h1 className="font-AvenirLTProHeavy  text-[2rem] leading-8">
              {item?.ItemQuantity}
            </h1>
            <h1 className="font-AvenirLTProHeavy text-[1rem]">{item?.Title}</h1>
          </div>
        </div>
      ))}
      <div
        onClick={() => {
          _ModalOpenHanlder();
        }}
        className={`hover:bg-gray-300 hover:border-none ease-in-out duration-300 h-[100px] select-none rounded-2xl border-[#747474] shadow-xl border-[1px]  flex items-center bg-[#EFEFEF] justify-between px-6 cursor-pointer`}
      >
        <img src={CreateIcon} alt="" />
        <div className="mx-auto ">
          <h1 className="font-AvenirLTProHeavy text-[1.6rem]  leading-6">
            {clickText}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default KpiComponents;
