import { Button } from "@mui/material";
import AutoComplete from "../AutoComplete";
import DatePickerComponent from "../DatePickerRe";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store";
import { useAxiosRead } from "../../CustomHook/useAxiosRead";

type Props = {
  onClick?: (e: any) => void;
};

const CustomFilterComponents = ({ onClick }: Props) => {
  const [isFilter, setIsFilter] = useState(false);
  const accessToken = useSelector(
    (state: RootState) => state.userLogin.accessToken
  );
  const _getLoyltyType = useAxiosRead(
    "/admin/api/get_dropdown_options?key=3",
    accessToken
  );
  const loyaltyTypes = _getLoyltyType?.data.map((item: any) => {
    return {
      label: item?.name,
      code: item?.id,
    };
  });

  const _getBrand = useAxiosRead(
    "/admin/api/get_dropdown_options?key=1",
    accessToken
  );
  const Barnds = _getBrand?.data.map((item: any) => {
    return {
      label: item?.name,
      code: item?.id,
    };
  });
  const titlteRef = useRef<HTMLInputElement>(null);

  const [filterValues, setFilterValues] = useState({
    title: titlteRef ? titlteRef?.current?.value : "",
    brand_id: "",
    loyalty_type_id: "",
    valid_from: "",
    valid_to: "",
  });

  const _onChangeHandler = ({
    name,
    value,
  }: {
    name: string;
    value: string;
  }) => {
    setFilterValues((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const _onSubmitHandler = () => {
    const isChangesAreMade = Object.values(filterValues).map((item) => {
      if (item === "") {
      } else {
        return true;
      }
    });
    if (isChangesAreMade && onClick) {
      setIsFilter(true);
      onClick(filterValues);
    }
  };
  const _clearFilter = () => {
    setFilterValues({
      title: "",
      brand_id: "",
      loyalty_type_id: "",
      valid_from: "",
      valid_to: "",
    });
    if (isFilter && onClick) {
      onClick({
        title: "",
        brand_id: "",
        loyalty_type_id: "",
        valid_from: "",
        valid_to: "",
      });
    }

    setIsFilter(false);
  };

  return (
    <div className="px-3 py-4 flex flex-grow  items-end w-full space-x-4">
      <div className="w-4/5">
        <div className=" grid grid-cols-5 gap-2 md:gap-4">
          <div className="flex flex-col flex-grow">
            {/* Title filter input Box */}
            <h1 className="text-[13px] tracking-wider font-AvenirLTProHeavy text-black">
              Title
            </h1>
            <input
              onChange={(e) =>
                _onChangeHandler({ name: "title", value: e?.target?.value })
              }
              value={filterValues.title}
              type="text"
              className="outline-none flex-grow flex  w-full border-[1px] border-[#A7A1A1]  rounded-md text-[14px] p-[8px] font-normal"
            />
          </div>

          {/* <div className="flex flex-col flex-grow">
          <h1 className="text-[13px] tracking-wider font-AvenirLTProHeavy text-black">
            Type
          </h1>
          <AutoComplete onClick={() => {}} data={[]} preValue="" />
        </div> */}
          <div className="flex flex-col flex-grow">
            {/* Brand filter Dropdown */}
            <h1 className="text-[13px] tracking-wider font-AvenirLTProHeavy text-black">
              Brand
            </h1>
            <AutoComplete
              onClick={(e) =>
                _onChangeHandler({ name: "brand_id", value: e?.code })
              }
              data={Barnds || []}
              preValue={filterValues?.brand_id}
            />
          </div>
          <div className="flex flex-col flex-grow">
            {/* Loyalty filter Dropdown*/}
            <h1 className="text-[13px] tracking-wider font-AvenirLTProHeavy text-black">
              Loyalty Type
            </h1>
            <AutoComplete
              onClick={(e) =>
                _onChangeHandler({ name: "loyalty_type_id", value: e?.code })
              }
              data={loyaltyTypes || []}
              preValue={filterValues?.loyalty_type_id}
            />
          </div>
          <div className="flex flex-col flex-grow">
            {/* From Data filter Dropdown */}
            <h1 className="text-[13px] tracking-wider font-AvenirLTProHeavy text-black">
              From Date
            </h1>
            <DatePickerComponent
              preValue={filterValues.valid_from}
              upDateValue={(e) =>
                _onChangeHandler({ name: "valid_from", value: e })
              }
            />
          </div>
          <div className="flex flex-col flex-grow">
            {/* To Date filter dropdown */}
            <h1 className="text-[13px] tracking-wider font-AvenirLTProHeavy text-black">
              To Date
            </h1>
            <DatePickerComponent
              preValue={filterValues.valid_to}
              upDateValue={(e) =>
                _onChangeHandler({ name: "valid_to", value: e })
              }
            />
          </div>
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        style={{
          borderRadius: 8,
          backgroundColor: "#000",
          padding: "18px 36px",
          fontSize: "15px",
          // textDecoration:
          textTransform: "none",
          fontFamily: "AvenirLTProHeavy",
          height: 40,
        }}
        onClick={_onSubmitHandler}
      >
        <img
          src={require("../../assets/FilterIcon.png")}
          alt="FilterIcon"
          className="w-5 h-5  mr-1"
        />
        Filter
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{
          borderRadius: 8,
          backgroundColor: "#ffffff",
          padding: "18px 36px",
          fontSize: "15px",
          // textDecoration:
          textTransform: "none",
          fontFamily: "AvenirLTProHeavy",
          height: 40,
          color: "#000",
        }}
        onClick={_clearFilter}
      >
        <img
          src={require("../../assets/ClearIcon.png")}
          alt="clearIcon"
          className="w-5 h-5 mr-1"
        />
        Clear
      </Button>
    </div>
  );
};

export default CustomFilterComponents;
